import PropTypes from "prop-types";
import CustomDialog from "../../common/CustomDialog";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { editProfileSchema } from "../../../schemas/settingsSchema";
import { useDispatch, useSelector } from "react-redux";
import { SettingsActions } from "../../api/RequestUtil";
import { SUCCESS } from "../../../utils/constants";
import AlertService from "../../common/Alert/alertService";
import { ErrorMessage } from "../../../utils/commonUtils";
import dayjs from "dayjs";
import EditProfileFormFields from "./EditProfileFormFields";
import { setUserFirstAndLastName } from "../../../redux/features/userSlice";

const EditProfile = ({ open, handleClose, refetchData }) => {
  const dispatch = useDispatch();

  const {
    accountOwnerDetails: {
      profileImage,
      firstName,
      lastName,
      phone,
      address,
      companyName,
      companyNumber,
      sex,
      dateOfBirth,
    },
  } = useSelector((state) => state.settings);

  const [selectedFile, setSelectedFile] = useState(profileImage);
  const { success, error } = AlertService();
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    trigger,
    getValues,
    reset,
  } = useForm({
    resolver: zodResolver(editProfileSchema),
    defaultValues: {
      firstName,
      lastName,
      address,
      dateOfBirth: dayjs(dateOfBirth).toDate() || "",
      companyName,
      companyNumber,
      sex: sex || "",
      image: profileImage,
      phone,
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setValue("image", file, { shouldDirty: true });
      trigger("image");
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setValue("image", "", { shouldDirty: true });
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    const formattedDateOfBirth = dayjs(data.dateOfBirth).format("YYYY-MM-DD");
    formData.append("first_name", data.firstName);
    formData.append("last_name", data.lastName);
    formData.append("address", data.address);
    formData.append("company_name", data.companyName);
    formData.append("company_number", data.companyNumber);
    formData.append("phone", data.phone);
    formData.append("sex", data.sex);
    formData.append("date_of_birth", formattedDateOfBirth);
    if (data.image) {
      formData.append("profile_image", data.image);
    }
    setLoader(true);
    SettingsActions.editProfile(formData)
      .then(({ data }) => {
        if (data.status === SUCCESS) {
          success(data.message);
          dispatch(
            setUserFirstAndLastName({
              firstName: data.firstName,
              lastName: data.lastName,
            })
          );
          handleCloseModal();
          refetchData();
        }
      })
      .catch(({ data }) => error(ErrorMessage(data)))
      .finally(() => setLoader(false));
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleCloseModal}
      title="Edit Profile"
      handleSave={handleSubmit(onSubmit)}
      loader={loader}
      disabled={!isDirty}
    >
      <EditProfileFormFields
        register={register}
        watch={getValues}
        setValue={setValue}
        trigger={trigger}
        errors={errors}
        getValues={getValues}
        handleFileChange={handleFileChange}
        handleRemoveFile={handleRemoveFile}
        selectedFile={selectedFile}
      />
    </CustomDialog>
  );
};

EditProfile.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetchData: PropTypes.func,
};

export default EditProfile;
