import { Box, Typography } from "@mui/material";

import theme from "../../theme";

const ComingSoon = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Typography
        variant="h1"
        component="h1"
        sx={{ fontSize: "6rem", fontWeight: "bold", color: theme.palette.primary.main }}
      >
        <img src="/assets/images/coming-soon.svg" width="72px" height="72px" />
      </Typography>
      <Typography variant="h4" component="h2" sx={{ marginBottom: "1rem" }}>
        Coming Soon
      </Typography>
      <Typography variant="h6" component="h2" sx={{ marginBottom: "1rem" }}>
        We're hard at work on something special. Stay tuned for more updates.
      </Typography>
    </Box>
  );
};

export default ComingSoon;
