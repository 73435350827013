import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Check if Firebase Messaging is supported
export let messaging = null;
(() => {
  if (isSupported()) {
    if ("serviceWorker" in navigator) {
      messaging = getMessaging(app);
      localStorage.setItem("isFirebaseMessagingSupported", "true");
    } else {
      localStorage.setItem("isFirebaseMessagingSupported", "false");
      console.warn(
        "Please use a supported browser to access Nativus Coach. Support browsers are listed on our website."
      );
    }
  } else {
    localStorage.setItem("isFirebaseMessagingSupported", "false");
    console.warn("Please use a supported browser to access Nativus Coach. Support browsers are listed on our website.");
  }
})();

export async function generateToken() {
  try {
    if (!messaging) {
      throw new Error("Firebase messaging is not supported.");
    }

    // Request notification permission
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      throw new Error("Notification permission not granted.");
    }

    // Get device ID using FingerprintJS
    const fp = await FingerprintJS.load();
    const { visitorId: deviceId } = await fp.get();
    localStorage.setItem("device_id", deviceId);

    // Ensure service worker is ready
    if (!("serviceWorker" in navigator)) {
      throw new Error("Service workers are not supported in this browser.");
    }
    const registration = await navigator.serviceWorker.ready;

    // Generate FCM token
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });

    if (token) {
      localStorage.setItem("fcm_token", token);
    } else {
      throw new Error("No FCM registration token available.");
    }
  } catch (error) {
    console.error("Error generating token:", error);
  }
}
