import { Box } from "@mui/material";
import PropTypes from "prop-types";

import Sidebar from "./common/Sidebar/Sidebar";

function Layout({ children }) {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, paddingY: 2, paddingX: "25px" }}>
        {children}
      </Box>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
