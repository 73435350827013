import { z } from "zod";

export const loginSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email"),
  password: z.string().min(1, "Password is required").min(8, "Password must be at least 8 characters long"),
  RememberMe: z.boolean(),
});

export const signUpSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email"),
  password: z.string().min(1, "Password is required").min(8, "Password must be at least 8 characters long"),
});

export const paymentSchema = z.object({
  cardNumber: z.string().min(19, "Card number must be 16 digits").max(19, "Card number must be 16 digits"),
  cardExpiry: z.string().regex(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Enter a valid expiry date (MM/YY)"),
  cardCVC: z.string().min(3, "CVC must be 3 digits").max(3, "CVC must be 3 digits"),
  cardName: z.string().min(2, "Name on card is required"),
});

export const yourDetailsSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  companyName: z.string().min(1, "Company name is required"),
  companyNumber: z
    .string()
    .regex(/^[a-zA-Z0-9 ]*$/, "Only letters, numbers, and spaces are allowed")
    .min(1, "ABN/Company Number is required"),
  phone: z.string().min(1, "Phone is required"),
  address: z.string().min(1, "Address is required"),
});

export const paymentSetUpFormSchema = z.object({
  cardNumber: z.string().min(1, "Card number is required"),
  expiry: z.string().min(1, "Expiry is required"),
  cvc: z.string().min(1, "CVC is required"),
  name: z.string().min(1, "Name on card is required"),
});

export const programSchema = z.object({
  name: z.string("").nonempty("Name is required"),
  description: z.string().optional(),
  feedback: z.string().optional(),
  type: z.string().nonempty("Type is required"),
  duration: z.coerce.number().int().min(1, "Duration is required").max(26, "Duration can not be larger than 26 Weeks"),
});

export const forgotPasswordSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email"),
});

export const ResetPasswordSchema = z
  .object({
    email: z.string().min(1, "Email is required").email("Invalid email"),
    password: z.string().min(1, "Password is required").min(8, "Password must be at least 8 characters long"),
    confirmPassword: z.string().min(1, "Confirm Password is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const setPasswordSchema = z
  .object({
    password: z.string().min(1, "Password is required").min(8, "Password must be at least 8 characters long"),
    confirmPassword: z.string().min(1, "Confirm Password is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const verifyOTPSchema = z.object({
  otp: z.coerce
    .number({
      invalid_type_error: "OTP must be a number",
    })
    .min(100000, "OTP must be 6 digits")
    .max(999999, "OTP must be 6 digits")
    .int("OTP must be an integer"),
});
