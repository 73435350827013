import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  isVerified: false,
  yourDetails: false,
  selectPlan: false,
  termsAndConditions: false,
  paymentSetup: false,
  displayCongrats: false,
  profileImage: "",
  planStatus: "",
  planRemainingGraceDays: null,
  isRiskFreeTier: false,
  isCardDetailsFilled: false,
  isStoppedNativusCoachSubscription: false,
  isFromSettings: false,
};

export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserFirstAndLastName: (state, action) => {
      const { firstName, lastName } = action.payload;

      state.firstName = firstName;
      state.lastName = lastName;
    },
    setUserDetails: (state, action) => {
      const {
        id,
        first_name,
        last_name,
        email,
        role,
        is_verified,
        your_details,
        select_plan,
        terms_and_conditions,
        payment_setup,
        profile_image,
        plan,
      } = action.payload;

      state.id = id;
      state.firstName = first_name;
      state.lastName = last_name;
      state.email = email;
      state.role = role;
      state.isVerified = is_verified;
      state.yourDetails = your_details;
      state.selectPlan = select_plan;
      state.termsAndConditions = terms_and_conditions;
      state.paymentSetup = payment_setup;
      state.profileImage = profile_image;
      state.planStatus = plan.status;
      state.planRemainingGraceDays = plan.remaining_grace_days;
      state.isRiskFreeTier = plan.isRiskFreeTier;
      state.isCardDetailsFilled = plan.isCardDetailsFilled;
      state.isStoppedNativusCoachSubscription = plan.isStoppedNativusCoachSubscription;
    },
    setYourDetailsCompleted: (state) => {
      state.yourDetails = true;
    },
    setSelectPlanCompleted: (state) => {
      state.selectPlan = true;
    },
    setTermsAndConditionsCompleted: (state) => {
      state.termsAndConditions = true;
    },
    setPaymentSetUpCompleted: (state) => {
      state.paymentSetup = true;
    },
    setDisplayCongrats: (state, action) => {
      state.displayCongrats = action.payload;
    },
    setPlanDetails: (state, action) => {
      state.planStatus = action.payload.status;
      state.planRemainingGraceDays = action.payload.planRemainingGraceDays;
    },
    setIsRiskFreeTier: (state, action) => {
      state.isRiskFreeTier = action.payload;
    },
    setIsCardDetailsFilled: (state, action) => {
      state.isCardDetailsFilled = action.payload;
    },
    setIsStoppedNativusCoachSubscription: (state, action) => {
      state.isStoppedNativusCoachSubscription = action.payload;
    },
    setIsFromSettings: (state, action) => {
      state.isFromSettings = action.payload;
    },
    resetUserDetails: () => initialState,
  },
});

export const {
  setUserFirstAndLastName,
  setUserDetails,
  setYourDetailsCompleted,
  setSelectPlanCompleted,
  setTermsAndConditionsCompleted,
  setPaymentSetUpCompleted,
  resetUserDetails,
  setDisplayCongrats,
  setPlanDetails,
  setIsRiskFreeTier,
  setIsCardDetailsFilled,
  setIsFromSettings,
  setIsStoppedNativusCoachSubscription,
} = userSlice.actions;

export default userSlice.reducer;
