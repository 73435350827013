import { z } from "zod";

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];

export const resetPasswordSchema = z
  .object({
    currentPassword: z.string().min(1, "Current password is required"),
    newPassword: z.string().min(1, "New password  is required"),
    confirmPassword: z.string().min(1, "Confirm password  is required"),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export const setUpSchema = z.object({
  weight: z.string().min(1, "Please select the Weight type"),
  distance: z.string().min(1, "Please select the Distance type"),
  currency: z.string().min(1, "Please select the Currency type"),
});

export const dateAndTimeFormFieldsSchema = z.object({
  timezone: z.string().min(1, "Timezone is required"),
  autoAdjustBasedOnLocation: z.boolean().optional(),
  date: z.string().min(1, "Date Format is required"),
});

export const paymentConnectionSchema = z.object({
  apiKey: z.string().min(1, "API key is required"),
  password: z.string().min(1, "Password is required"),
});

export const editProfileSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  address: z.string().min(1, "Address is required"),
  companyName: z.string().min(1, "Company Name is required"),
  companyNumber: z.string().min(1, "Company Number is required"),
  phone: z.string().min(1, "Phone is required"),
  dateOfBirth: z
    .date({
      required_error: "Date of birth is required",
      invalid_type_error: "Date of birth is required",
    })
    .refine((date) => date instanceof Date && !isNaN(date), {
      message: "Start Date is required",
    }),
  sex: z.string().min(1, "Sex is required"),
  image: z.union([
    z.instanceof(File, "Image file is required").refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
      message: "File type must be an accepted image type",
    }),
    z.string().optional(),
  ]),
});
