import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warmUp: "",
  coolDown: "",
  notes: "",
  isRowAdd: null,
  isRowRemove: null,
  showLeavePopUp: false,
  weeks: null,
};

export const blankSessionSlice = createSlice({
  name: "blankSession",
  initialState,
  reducers: {
    addWarmUp: (state, action) => {
      state.warmUp = action.payload;
    },
    addCoolDown: (state, action) => {
      state.coolDown = action.payload;
    },
    addNotes: (state, action) => {
      state.notes = action.payload;
    },
    setAddRow: (state, action) => {
      state.isRowAdd = action.payload;
    },
    setRemoveRow: (state, action) => {
      state.isRowRemove = action.payload;
    },
    setShowLeavePopUp: (state) => {
      state.showLeavePopUp = !state.showLeavePopUp;
    },
    setWeekData: (state, action) => {
      state.weeks = action.payload.map((row) => ({
        ...row,
      }));
    },
  },
});

export const { addWarmUp, addCoolDown, addNotes, setAddRow, setRemoveRow, setShowLeavePopUp, setWeekData } =
  blankSessionSlice.actions;

export default blankSessionSlice.reducer;
