import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import CustomDialog from "../../common/CustomDialog";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from "../../../utils/commonUtils";
import { SUCCESS } from "../../../utils/constants";
import { paymentSchema } from "../../../utils/schema";
import { ClientProfileActions, SettingsActions } from "../../api/RequestUtil";
import AlertService from "../../common/Alert/alertService";
import PaymentForm from "../../common/PaymentForm";
import PaymentSuccess from "./PaymentSuccess";
import theme from "../../../theme";
import { setIsCardDetailsFilled, setPlanDetails } from "../../../redux/features/userSlice";
import { PAYMENT_STATUS_ACTIVE, PAYMENT_STATUS_PAST_DUE } from "../../../constants/constant";

const UpdateBilling = ({
  open,
  handleClose,
  title = "Update Billing",
  handleSaveTitle = "Update",
  isPayNow,
  clientId,
  subscriptionId,
  refetchData,
  updateBillingMessage = "",
  setChangePlan = () => {},
  showChangePlanAfterUpdateBilling = false,
}) => {
  const dispatch = useDispatch();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const {
    accountOwnerDetails: { address, firstName, lastName, companyName, email, phone },
  } = useSelector((state) => state.settings);
  const { isRiskFreeTier, planRemainingGraceDays } = useSelector((state) => state.user);
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const { success, error } = AlertService();

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(paymentSchema),
    defaultValues: {
      cardName: "",
      cardNumber: "",
      cardExpiry: "",
      cardCVC: "",
    },
  });

  useEffect(() => {
    if (open && address) {
      const addressParts = address.split(",");
      const extractedCountry = addressParts[addressParts.length - 1].trim();
      setCountry(extractedCountry);
    }
  }, [open, address]);

  const submitToMktoForms = () => {
    if (country === "New Zealand" || country === "Australia") {
      const myForm = window.MktoForms2.allForms()[0];
      myForm.addHiddenFields({
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        Company: companyName,
        eWAY_Branch__c: country,
      });
      window.MktoForms2.whenReady((myForm2) => {
        myForm2.submit();
        myForm2.onSuccess(() => false);
      });
    }
  };

  const processPayment = async (cardData) => {
    try {
      setLoader(true);
      const response = isPayNow
        ? await ClientProfileActions.payNowForSubscription(clientId, subscriptionId, cardData)
        : await SettingsActions.updateBillingPayAndUpdateToken(cardData);

      submitToMktoForms();

      if (response.data.status === SUCCESS) {
        success(response.data?.message);
        !showChangePlanAfterUpdateBilling && setPaymentSuccess(true);
        showChangePlanAfterUpdateBilling && setChangePlan(true);

        if (isRiskFreeTier) {
          dispatch(
            setPlanDetails({
              status: PAYMENT_STATUS_PAST_DUE,
              planRemainingGraceDays,
            })
          );
          dispatch(setIsCardDetailsFilled(true));
        } else {
          dispatch(
            setPlanDetails({
              status: PAYMENT_STATUS_ACTIVE,
              planRemainingGraceDays: null,
            })
          );
        }
      }
    } catch (err) {
      error(ErrorMessage(err.data));
    } finally {
      setLoader(false);
      handleCloseModal();
    }
  };

  const onSubmit = async (data) => {
    const key = process.env.REACT_APP_EWAY_API_KEY;
    const cardData = {
      name_on_card: data.cardName,
      card_expiry: data.cardExpiry.replace("/", ""),
      card_number: window.eCrypt.encryptValue(data.cardNumber, key),
      card_cvn: window.eCrypt.encryptValue(data.cardCVC, key),
    };
    processPayment(cardData);
  };

  const handleCloseModal = () => {
    handleClose();
    reset();
  };

  return (
    <>
      <CustomDialog
        title={title}
        open={open}
        handleClose={handleCloseModal}
        handleSaveTitle={handleSaveTitle}
        handleSave={handleSubmit(onSubmit)}
        loader={loader}
        onCloseButtonClick={handleCloseModal}
      >
        {updateBillingMessage && (
          <Typography color={theme.palette.red.dark} fontWeight="700" marginBottom="20px">
            {updateBillingMessage}
          </Typography>
        )}
        <Box component="form" marginTop={1}>
          <PaymentForm control={control} errors={errors} defaultValues={{}} register={register} />
        </Box>
      </CustomDialog>
      {paymentSuccess && (
        <PaymentSuccess
          open={paymentSuccess}
          handleClose={() => {
            setPaymentSuccess(false);
            refetchData && refetchData();
          }}
        />
      )}
    </>
  );
};

UpdateBilling.propTypes = {
  open: PropTypes.bool,
  isPayNow: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCloseButtonClick: PropTypes.func,
  title: PropTypes.string,
  handleSaveTitle: PropTypes.string,
  clientId: PropTypes.string,
  subscriptionId: PropTypes.string,
  refetchData: PropTypes.func,
  updateBillingMessage: PropTypes.string,
  setChangePlan: PropTypes.func,
  showChangePlanAfterUpdateBilling: PropTypes.bool,
};

export default UpdateBilling;
