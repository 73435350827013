import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import theme from "../theme/";

const Unauthorize = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Typography
        variant="h1"
        component="h1"
        sx={{ fontSize: "6rem", fontWeight: "bold", color: theme.palette.primary.main }}
      >
        401
      </Typography>
      <Typography variant="h6" component="h2" sx={{ marginBottom: "1rem" }}>
        Oops! You are not authorized to view this page.
      </Typography>
      <Button variant="contained" color="primary" onClick={goBack}>
        Go Back
      </Button>
    </Box>
  );
};

export default Unauthorize;
