import { useSelector } from "react-redux";
import { ROLES } from "../../constants/constant";

const useRoles = () => {
  const { role } = useSelector((state) => state.user);

  return {
    isManager: role === ROLES.MANAGER,
    isCoach: role === ROLES.COACH,
  };
};

export default useRoles;
