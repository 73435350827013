import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

import theme from "../../theme";
import { useSelector } from "react-redux";

function SelectPlanCard({ data, isSelected, onClick }) {
  const { id, name, cost, features, clients, users, processing_fee } = data;

  const {
    currentPlan: { planId },
  } = useSelector((state) => state.settings);

  const isCurrentPlan = planId === id;

  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.white.light,
        cursor: "pointer",
        color: isSelected ? theme.palette.common.white : theme.palette.common.black,
        width: "100%",
        maxWidth: "400px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        borderRadius: "12px",
      }}
    >
      <Stack spacing="16px" paddingX="18px" paddingY="24px">
        <Stack spacing={1}>
          <Typography
            variant="h6"
            component="h1"
            textAlign="center"
            fontWeight="700"
            fontSize={{ xs: "24px", sm: "28px", md: "32px", lg: "36px" }}
          >
            {name} {isCurrentPlan && "(Current)"}
          </Typography>
          <Typography
            variant="subtitle2"
            color={isSelected ? "white" : theme.palette.primary.main}
            fontWeight="700"
            fontSize="20px"
            textAlign="center"
          >
            {cost}
          </Typography>
        </Stack>
        <Box paddingX="12px" paddingY="8px" sx={{ overflowY: "auto" }}>
          <List sx={{ listStyleType: "disc", paddingLeft: "10px" }} disablePadding>
            <ListItem
              disablePadding
              sx={{
                display: "list-item",
                paddingLeft: "0px",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              <Typography>Access to the following features:</Typography>
              <List sx={{ listStyleType: "disc", paddingLeft: "20px" }} disablePadding>
                {features.map((feature, index) => (
                  <ListItem
                    disablePadding
                    key={index}
                    sx={{
                      display: "list-item",
                      fontSize: { xs: "14px", sm: "16px" },
                    }}
                  >
                    <Typography>{feature}</Typography>
                  </ListItem>
                ))}
              </List>
            </ListItem>
          </List>
        </Box>

        <Box paddingX="12px" paddingY="8px" sx={{ overflowY: "auto" }}>
          <List sx={{ listStyleType: "disc", paddingLeft: "10px" }} disablePadding>
            <ListItem
              sx={{
                display: "list-item",
                paddingLeft: "0px",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              <Typography>{clients}</Typography>
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
                paddingLeft: "0px",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              <Typography>{users}</Typography>
            </ListItem>
            {processing_fee && (
              <ListItem
                sx={{
                  display: "list-item",
                  paddingLeft: "0px",
                  fontSize: { xs: "14px", sm: "16px" },
                }}
              >
                <Typography>{processing_fee} processing fee on payments</Typography>
              </ListItem>
            )}
          </List>
        </Box>
      </Stack>
    </Box>
  );
}

SelectPlanCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cost: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    clients: PropTypes.string.isRequired,
    users: PropTypes.string.isRequired,
    processing_fee: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SelectPlanCard;
