import { generatePath } from "react-router-dom";
import qs from "qs";

const removeEmptyParams = (params) =>
  Object.keys(params).reduce((result, key) => {
    const value = params[key];
    if (value !== null && value !== undefined) {
      result[key] = value;
    }
    return result;
  }, {});

export const buildPath = (url, params) => generatePath(url, params);

export const buildPathForApi = (url, params, removeEmpty = true) => {
  // Remove all null/empty vales like axios would
  params = removeEmpty ? removeEmptyParams(params) : params;

  const qstr = qs.stringify(params, {
    arrayFormat: "repeat",
  });

  return generatePath(url, params) + (qstr ? "?" + qstr : "");
};
