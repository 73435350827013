import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAYMENT_STATUS_ACTIVE } from "../../../constants/constant";
import { setIsRiskFreeTier, setPlanDetails } from "../../../redux/features/userSlice";
import { ErrorMessage } from "../../../utils/commonUtils";
import { SUCCESS } from "../../../utils/constants";
import { SettingsActions } from "../../api/RequestUtil";
import AlertService from "../../common/Alert/alertService";
import CustomDialog from "../../common/CustomDialog";
import AccountDetails from "./AccountDetails";
import SelectPlan from "./SelectPlan";

const ChangePlan = ({ open, handleClose, handleCloseButtonClick, refetchData = () => {} }) => {
  const dispatch = useDispatch();
  const { warning, error, success } = AlertService();
  const [loader, setLoader] = useState(false);

  const {
    currentPlan: { planId },
  } = useSelector((state) => state.settings);
  const { isRiskFreeTier } = useSelector((state) => state.user);

  const [selectedPlanId, setSelectedPlanId] = useState(planId);
  const [currentStepCount, setCurrentStepCount] = useState(0);

  const onCloseButtonClick = () => {
    handleCloseButtonClick ? handleCloseButtonClick() : handleClose();
    setCurrentStepCount(0);
    setSelectedPlanId(planId);
  };

  const handleStopUsingNativusCoach = () => {
    setLoader(true);

    SettingsActions.stopPlan()
      .then(({ data }) => {
        if (data.status === SUCCESS) {
          success(data.message);
          handleClose();
          setCurrentStepCount(0);
          refetchData && refetchData();
        }
      })
      .catch(({ data }) => error(ErrorMessage(data)))
      .finally(() => setLoader(false));
  };

  const changePlanSteps = [
    {
      step: 0,
      label: "Change Plan - Please select a New Plan",
      component: <SelectPlan selectedPlanId={selectedPlanId} setSelectedPlanId={setSelectedPlanId} />,
      onNextClick: () => {
        if (!selectedPlanId) {
          warning("Please select any of plan");
          return;
        }
        if (selectedPlanId === planId) {
          warning("Please choose different plan to continue.");
          return;
        }
        if (selectedPlanId) setCurrentStepCount(currentStepCount + 1);
      },
      onPreviousClick: () => {
        handleClose();
        setSelectedPlanId(planId);
      },
      cancelTitle: "Cancel",
      saveTitle: "Next",
    },
    {
      step: 1,
      label: "Change Plan - Your New Payment Schedule",
      component: <AccountDetails selectedPlanId={selectedPlanId} />,
      onNextClick: () => {
        setLoader(true);
        SettingsActions.changePlan({
          new_plan_id: selectedPlanId,
        })
          .then(({ data }) => {
            if (data.status === SUCCESS) {
              success(data.message);
              handleClose();
              setCurrentStepCount(0);
              refetchData && refetchData();

              if (isRiskFreeTier) {
                dispatch(setIsRiskFreeTier(false));
                dispatch(
                  setPlanDetails({
                    status: PAYMENT_STATUS_ACTIVE,
                    planRemainingGraceDays: null,
                  })
                );
              }
            }
          })
          .catch(({ data }) => error(ErrorMessage(data)))
          .finally(() => setLoader(false));
      },
      onPreviousClick: () => setCurrentStepCount(currentStepCount - 1),
      cancelTitle: "Cancel",
      saveTitle: "Confirm",
    },
  ];

  const currentStep = changePlanSteps[currentStepCount];

  return (
    <CustomDialog
      title={currentStep.label}
      open={open}
      onCloseButtonClick={onCloseButtonClick}
      handleClose={currentStep.onPreviousClick}
      dialogWidth={currentStepCount === 0 ? "lg" : "md"}
      handleSaveTitle={currentStep.saveTitle}
      handleCancelTitle={currentStep.cancelTitle}
      handleSave={currentStep.onNextClick}
      reverseButtons
      loader={loader}
      thirdButtonText="Stop using Nativus Coach"
      isThirdButtonExist={currentStepCount === 0}
      handleThirdButtonOnClick={handleStopUsingNativusCoach}
      thirdButtonLoader={true}
    >
      {currentStep.component}
    </CustomDialog>
  );
};

ChangePlan.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCloseButtonClick: PropTypes.func,
  refetchData: PropTypes.func,
};

export default ChangePlan;
