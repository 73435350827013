import { Box, CircularProgress, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { currencySymbols } from "../../../constants/currency.constants";
import { ErrorMessage } from "../../../utils/commonUtils";
import { SUCCESS } from "../../../utils/constants";
import { SettingsActions } from "../../api/RequestUtil";
import AlertService from "../../common/Alert/alertService";

const StyledTypography = styled(Typography)({
  fontSize: "16px",
});

const AccountDetails = ({ selectedPlanId }) => {
  const { currency } = useSelector((state) => state.userSettings);
  const currentCurrencySymbol = currencySymbols.find((curr) => curr.value === currency)?.name;

  const transformApiResponse = (data) => {
    const { current_plan, new_plan, payment_amount, next_payment_date } = data;

    return [
      {
        title: `Current Subscription Credit - ${current_plan?.name} (${current_plan?.start_date} - ${current_plan?.end_date}) - ${current_plan?.remaining_days} days`,
        amount: current_plan?.credit_amount,
        show: current_plan?.start_date && current_plan?.end_date && current_plan?.remaining_days,
      },
      {
        title: `New Subscription - ${new_plan?.name}`,
        amount: new_plan?.cost,
        show: new_plan?.name,
      },
      {
        title: `Payment Processed Today (Due Today: ${next_payment_date})`,
        amount: payment_amount,
        show: next_payment_date,
      },
      {
        title: `New monthly subscription cost (Due: ${next_payment_date})`,
        amount: new_plan?.cost,
        show: next_payment_date,
      },
    ];
  };

  const [planDetails, setPlanDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const { error } = AlertService();

  const fetchPlanDetails = () => {
    setLoader(true);
    SettingsActions.getPlanDetails({
      new_plan_id: selectedPlanId,
    })
      .then(({ data }) => {
        if (data.status === SUCCESS) {
          const transformedData = transformApiResponse(data.data);
          setPlanDetails(transformedData);
        }
      })
      .catch((data) => error(ErrorMessage(data)))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  return (
    <>
      {loader ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="65vh">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        planDetails.map(
          (payment, index) =>
            payment.show && (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  height: "70px",
                  padding: "16px 30px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                }}
              >
                <StyledTypography>{payment.title} </StyledTypography>
                {payment.amount !== null && payment?.amount !== undefined ? (
                  <StyledTypography color={payment.amount > 0 ? "error" : "textPrimary"}>
                    {currentCurrencySymbol} {payment.amount}
                  </StyledTypography>
                ) : null}
              </Box>
            )
        )
      )}
    </>
  );
};

AccountDetails.propTypes = {
  selectedPlanId: PropTypes.string,
};

export default AccountDetails;
