import { createSlice } from "@reduxjs/toolkit";

import { allowedDuplicateFields } from "../../constants/forms.constants";

const initialState = {
  name: "",
  description: "",
  type: "",
  priority: "",
  transferToClientRecord: false,
  selectedFormField: {},
  dynamicFields: [],
  activeTabIndex: 0,
  viewOnly: false,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormName: (state, action) => {
      state.name = action.payload;
    },
    setFormDescription: (state, action) => {
      state.description = action.payload;
    },
    setFormType: (state, action) => {
      state.type = action.payload;
    },
    setFormPriority: (state, action) => {
      state.priority = action.payload;
    },
    setSelectedFormField: (state, action) => {
      state.selectedFormField = action.payload;
    },
    setDynamicFormFields: (state, action) => {
      const newField = action.payload;
      const previousDynamicFields = state.dynamicFields;

      return {
        ...state,
        dynamicFields: [...previousDynamicFields, newField],
      };
    },
    moveDynamicFormFields: (state, action) => {
      state.dynamicFields = action.payload;
    },
    updateDynamicFormField: (state, action) => {
      const { fieldTag, updatedField } = action.payload;
      const previousDynamicFields = state.dynamicFields;

      const updatedDynamicFields = previousDynamicFields.map((dynamicField) =>
        dynamicField.fieldTag === fieldTag ? updatedField : dynamicField
      );

      return {
        ...state,
        dynamicFields: updatedDynamicFields,
      };
    },
    deleteDynamicFormField: (state, action) => {
      const previousDynamicFields = state.dynamicFields;
      const deleteFieldTag = action.payload;

      const updatedDynamicFields = previousDynamicFields.filter(
        (dynamicField) => dynamicField.fieldTag !== deleteFieldTag
      );

      return {
        ...state,
        dynamicFields: updatedDynamicFields,
      };
    },
    setActiveTabIndex: (state, action) => {
      state.activeTabIndex = action.payload;
    },
    setFreeTextField: (state, action) => {
      const previousDynamicFields = state.dynamicFields;
      const { fieldTag, defaultValue } = action.payload;

      const updatedDynamicFields = previousDynamicFields.map((dynamicField) =>
        dynamicField.fieldTag === fieldTag ? { ...dynamicField, defaultValue } : dynamicField
      );

      return {
        ...state,
        dynamicFields: updatedDynamicFields,
      };
    },
    setFormData: (state, action) => {
      const { name, description, type, priority, view_only, form_fields, transfer_to_client_record_fields } =
        action.payload.formData;

      state.name = name;
      state.description = description;
      state.type = type;
      state.priority = priority;
      state.viewOnly = view_only;
      state.transferToClientRecord = transfer_to_client_record_fields;
      state.dynamicFields = form_fields.map((field) => {
        const allowDuplicate = allowedDuplicateFields.some((fieldType) =>
          field.field_tag.toLowerCase().includes(fieldType.toLowerCase())
        );

        const filteredField = Object.fromEntries(
          Object.entries({
            fieldType: field.field_type || "",
            fieldLabel: field.field_name || "",
            fieldTag: field.field_tag || "",
            isRequired: field.required || false,
            allowDuplicate,
            readOnly: field.read_only || false,
            defaultValue: field.default_value || "",
            fieldOptions: field.field_options || [],
            fieldGroup: field.field_group || null,
            fields:
              field.fields?.map((subField) => ({
                fieldLabel: subField.field_name || "",
                fieldTag: subField.field_tag || "",
                fieldValue: subField.field_value || "",
                fieldType: subField.field_type || "",
                isRequired: subField.required || false,
                fieldNote: subField.field_note || "",
              })) || [],
            range: field.range || "",
            fieldValue: field.field_value || "",
          }).filter(
            ([, value]) =>
              value !== "" && value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0)
          )
        );

        return filteredField;
      });
    },
    resetDynamicAndSelectedFormFields: (state) => ({ ...state, selectedFormField: {}, dynamicFields: [] }),
    resetForm: () => ({ ...initialState }),
  },
});

export const {
  setFormName,
  setFormDescription,
  setFormType,
  setFormPriority,
  setSelectedFormField,
  setDynamicFormFields,
  updateDynamicFormField,
  deleteDynamicFormField,
  setActiveTabIndex,
  setFreeTextField,
  setFormData,
  resetDynamicAndSelectedFormFields,
  resetForm,
  moveDynamicFormFields,
} = formSlice.actions;

export default formSlice.reducer;
