import { Avatar, Box, Button, Stack, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import theme from "../../../theme";
import EditProfile from "./EditProfile";
import ResetPasswordModal from "./ResetPasswordModal";

const ProfileInfo = ({ label = "", icon = "" }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
    {icon && <Box component="img" src={icon} height="24px" width="24px" />}
    <Typography variant="body2" sx={{ fontSize: "14px", color: theme.palette.gray.medium }}>
      {label}
    </Typography>
  </Box>
);

export const getGenderIcon = (sex) => {
  const genderIcons = {
    male: "/assets/images/maleGender.svg",
    female: "/assets/images/femaleGender.svg",
    other: "/assets/images/otherGender.svg",
    default: "/assets/images/neutralGender.svg",
  };
  return genderIcons[sex?.toLowerCase() || ""] ?? genderIcons.default;
};

const DisplayDetails = ({ label, value }) => (
  <Stack gap="4px">
    <Typography fontSize="14px" color={theme.palette.gray.light}>
      {label}
    </Typography>
    <Typography fontSize="14px" color={theme.palette.black.light}>
      {value}
    </Typography>
  </Stack>
);

const StyledButton = styled(Button)(({ theme }) => ({
  width: "158px",
  height: "48px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "12px",
  color: theme.palette.common.white,
  fontWeight: "700",
}));

const UserProfile = ({ refetchData }) => {
  const {
    accountOwnerDetails: {
      profileImage,
      role,
      firstName,
      lastName,
      email,
      phone,
      address,
      companyName,
      companyNumber,
      age,
      sex,
    },
  } = useSelector((state) => state.settings);

  const [resetPassword, setResetPassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const handleResetPassword = () => setResetPassword(!resetPassword);

  const handleEditProfile = () => setShowEditProfile(!showEditProfile);

  return (
    <>
      <Stack paddingLeft={1}>
        <Stack
          borderRadius="24px"
          height="523px"
          width="682px"
          marginTop="20px"
          border={`1px solid ${theme.palette.white.light}`}
          padding={"16px 24px"}
          gap="16px"
        >
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <Avatar alt={`${firstName} ${lastName}`} src={profileImage} sx={{ width: 72, height: 72 }} />
              <Box>
                <Typography variant="caption" sx={{ color: theme.palette.gray.medium, fontSize: "14px" }}>
                  {role}
                </Typography>
                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: theme.palette.charcoal, fontSize: "16px" }}>
                    {`${firstName} ${lastName}`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mt: 0.5 }}>
                  <ProfileInfo label={sex} icon={getGenderIcon(sex)} />
                  <ProfileInfo label={age} icon={"/assets/images/cake.svg"} />
                </Box>
              </Box>
            </Box>
            <Box
              component="img"
              src={"/assets/images/edit.svg"}
              alt="edit icon"
              sx={{ cursor: "pointer" }}
              marginBottom={0.2}
              onClick={handleEditProfile}
            />
          </Box>

          <Stack
            sx={{ background: theme.palette.gray.lighter }}
            height="325px"
            width="634px"
            borderRadius="12px"
            padding="12px"
            gap="19px"
          >
            <DisplayDetails label="Email" value={email} />
            <DisplayDetails label="Phone" value={phone} />
            <DisplayDetails label="Address" value={address} />
            <DisplayDetails label="Company Name" value={companyName} />
            <DisplayDetails label="Company Number" value={companyNumber} />
          </Stack>

          <StyledButton onClick={handleResetPassword}>Reset Password</StyledButton>
        </Stack>
      </Stack>
      <ResetPasswordModal open={resetPassword} handleClose={handleResetPassword} />

      {showEditProfile && (
        <EditProfile open={showEditProfile} handleClose={handleEditProfile} refetchData={refetchData} />
      )}
    </>
  );
};

ProfileInfo.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
};
DisplayDetails.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

UserProfile.propTypes = {
  refetchData: PropTypes.func,
};

export default UserProfile;
