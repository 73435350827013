export default function componentStyleOverrides(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputBase-input": {
            backgroundColor: theme.palette.white.light,
            borderRadius: "12px",
            height: "40px",
            padding: "0 10px",
            fontSize: "16px",
          },
          "& .MuiFormHelperText-root": {
            marginLeft: "10px",
          },
          "& .MuiSelect-select": {
            height: "40px !important",
            lineHeight: "40px !important",
            borderRadius: "12px !important",
          },
        },
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: theme.palette.common.black,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.common.white,
          minWidth: "50px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "8px 12px",
          height: "54px",
          borderRadius: "12px",
          fontSize: "14px",
          textTransform: "none",
          color: theme.palette.primary,
          typography: { xs: "body2", sm: "body1" },
          "&:hover": { background: theme.palette.primary.main, color: theme.palette.common.white },
        },
      },
    },
  };
}
