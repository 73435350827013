export const FormType = {
  LOGIN: "login",
  SIGNUP: "signup",
  FORGOTPASSWORD: "forgot password",
  RESETPASSWORD: "reset password",
  SETPASSWORD: "set-password",
  VERIFYOTP: "verify-otp",
};

export const builderFormTypes = [
  { value: "agility", name: "Agility" },
  { value: "balance", name: "Balance" },
  { value: "endurance", name: "Endurance" },
  { value: "flexibility", name: "Flexibility" },
  { value: "group-training", name: "Group Training" },
  { value: "hypertrophy", name: "Hypertrophy" },
  { value: "mixed", name: "Mixed" },
  { value: "power", name: "Power" },
  { value: "speed", name: "Speed" },
  { value: "strength", name: "Strength" },
  { value: "weight-loss", name: "Weight Loss" },
  { value: "other", name: "Other" },
];

export const difficulty = [
  { value: "easy", name: "Easy" },
  { value: "medium", name: "Medium" },
  { value: "hard", name: "Hard" },
];

export const sexOptions = [
  { value: "male", name: "Male" },
  { value: "female", name: "Female" },
  { value: "other", name: "Other" },
];

export const ROLES = {
  MANAGER: "manager",
  COACH: "coach",
};

export const roleOptions = [
  { value: ROLES.MANAGER, name: "Manager" },
  { value: ROLES.COACH, name: "Coach" },
];

export const lastEditedOptions = ["today", "yesterday", "this week", "last week", "this month", "last month"];

export const editedOptions = ["today", "tomorrow"];

export const API_LIMIT = 10;
export const CHAR_LIMIT = 2000;
