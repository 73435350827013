// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fui-CalendarDay__monthAndYear {
    font-weight: 900 !important;
  }
  
  .fui-CalendarPicker__currentItemButton {
    font-weight: 900 !important;
  }
  
  .fui-CalendarPicker__selected {
    background-color: #013D29 !important;  
    color: white !important;  
  }
  
  .fui-CalendarDayGrid__dayIsToday {
    background-color: #013D29 !important;  
    color: white !important;  
    border-radius: 50% !important;
  }
  
  .fui-Calendar__goTodayButton:disabled {
    color: gray !important;
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/fui-styles.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,oCAAoC;IACpC,uBAAuB;EACzB;;EAEA;IACE,oCAAoC;IACpC,uBAAuB;IACvB,6BAA6B;EAC/B;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".fui-CalendarDay__monthAndYear {\n    font-weight: 900 !important;\n  }\n  \n  .fui-CalendarPicker__currentItemButton {\n    font-weight: 900 !important;\n  }\n  \n  .fui-CalendarPicker__selected {\n    background-color: #013D29 !important;  \n    color: white !important;  \n  }\n  \n  .fui-CalendarDayGrid__dayIsToday {\n    background-color: #013D29 !important;  \n    color: white !important;  \n    border-radius: 50% !important;\n  }\n  \n  .fui-Calendar__goTodayButton:disabled {\n    color: gray !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
