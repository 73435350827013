import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import PropTypes from "prop-types";
import theme from "../../theme";

const CustomDialogButton = ({ label, onClick, variant, sx, loader, endIcon, disabled = false }) => (
  <Button
    variant={variant}
    disabled={disabled}
    sx={sx}
    onClick={onClick}
    endIcon={loader && endIcon ? <CircularProgress size={16} color="white" /> : null}
  >
    {label}
  </Button>
);

CustomDialogButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  sx: PropTypes.object,
  loader: PropTypes.bool,
  endIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};

const CustomDialog = ({
  open,
  onCloseButtonClick,
  handleClose,
  handleSave,
  title = "",
  children,
  loader = false,
  dialogWidth = "xs",
  handleSaveTitle = "Confirm",
  handleCancelTitle = "Cancel",
  reverseButtons = false,
  hideSaveButton = false,
  buttonColor = theme.palette.primary.main,
  showCancelButton = true,
  disabled = false,
  isThirdButtonExist = false,
  handleThirdButtonOnClick = () => {},
  thirdButtonText = "",
  thirdButtonLoader = false,
  displayCloseIcon = true,
}) => {
  const handleDialogClose = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    } else {
      handleClose();
    }
  };
  const buttonSx = {
    flex: 1,
    height: "45px !important",
    borderRadius: "16px !important",
    ":hover": {
      backgroundColor: buttonColor,
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        borderRadius: "24px",
        "& .MuiPaper-root": {
          borderRadius: "24px",
          maxWidth: dialogWidth,
        },
        height: "auto",
      }}
      fullWidth
      maxWidth={dialogWidth}
    >
      <DialogTitle
        id="custom-dialog-title"
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: 600,
          paddingRight: "48px",
        }}
      >
        {title}
        {displayCloseIcon && (
          <IconButton
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Box id="custom-dialog-description">{children}</Box>
      </DialogContent>
      {(!hideSaveButton || showCancelButton) && (
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          {isThirdButtonExist && (
            <CustomDialogButton
              label={thirdButtonText}
              onClick={handleThirdButtonOnClick}
              variant="outlined"
              sx={{
                ...buttonSx,
                marginRight: 1,
              }}
              loader={thirdButtonLoader}
            />
          )}
          {!hideSaveButton && (
            <CustomDialogButton
              label={reverseButtons ? handleCancelTitle : handleSaveTitle}
              onClick={reverseButtons ? handleClose : handleSave}
              disabled={disabled}
              variant={reverseButtons ? "outlined" : "contained"}
              sx={{
                ...buttonSx,
                marginRight: 1,
                backgroundColor: !reverseButtons && buttonColor,
              }}
              loader={loader && !reverseButtons}
              endIcon={!reverseButtons}
            />
          )}
          {showCancelButton && (
            <CustomDialogButton
              label={reverseButtons ? handleSaveTitle : handleCancelTitle}
              onClick={reverseButtons ? handleSave : handleClose}
              variant={reverseButtons || hideSaveButton ? "contained" : "outlined"}
              sx={{
                ...buttonSx,
                marginLeft: 1,
                borderColor: !hideSaveButton && !reverseButtons && buttonColor,
                color: !hideSaveButton && !reverseButtons && buttonColor,
                ":hover": {
                  backgroundColor: buttonColor,
                  borderColor: !hideSaveButton && !reverseButtons && buttonColor,
                },
              }}
              loader={loader && reverseButtons}
              endIcon={reverseButtons}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseButtonClick: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  loader: PropTypes.bool,
  dialogWidth: PropTypes.string,
  handleSaveTitle: PropTypes.string,
  handleCancelTitle: PropTypes.string,
  reverseButtons: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  buttonColor: PropTypes.string,
  showCancelButton: PropTypes.bool,
  disabled: PropTypes.bool,
  isThirdButtonExist: PropTypes.bool,
  handleThirdButtonOnClick: PropTypes.func,
  thirdButtonText: PropTypes.string,
  thirdButtonLoader: PropTypes.bool,
  displayCloseIcon: PropTypes.bool,
};

export default CustomDialog;
