import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: "",
  weight: "",
  distance: "",
  timezone: "",
  dateFormat: "",
};

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setUserSettings: (state, action) => {
      const { currency, weight, distance, timezone, date_format } = action.payload;

      state.currency = currency;
      state.weight = weight;
      state.distance = distance;
      state.timezone = timezone;
      state.dateFormat = date_format;
    },
    setUserTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    resetUserSettings: () => initialState,
  },
});

export const { setUserSettings, setUserTimezone, resetUserSettings } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
