import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export async function generateToken() {
  try {
    // Request notification permission
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      throw new Error("Notification permission not granted.");
    }

    // Get device ID using FingerprintJS
    const fp = await FingerprintJS.load();
    const { visitorId: deviceId } = await fp.get();
    localStorage.setItem("device_id", deviceId);

    const registration = await navigator.serviceWorker.ready;

    // Generate FCM token
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });

    if (token) {
      localStorage.setItem("fcm_token", token);
    } else {
      throw new Error("No FCM registration token available.");
    }
  } catch (error) {
    console.error("Error generating token:", error);
  }
}
