export const TABLE_HEADER_DROPDOWN_OPTIONS = [
  {
    displayName: "Sets",
    value: "sets",
    selected: false,
  },
  {
    displayName: "Reps",
    value: "reps",
    selected: false,
  },
  {
    displayName: "Rx Intensity %",
    value: "rx_intensity",
    selected: false,
  },
  {
    displayName: "Rx Load",
    value: "rx_load",
    selected: false,
  },
  {
    displayName: "Actual Load",
    value: "actual_load",
    selected: false,
  },
  {
    displayName: "Rx Weight",
    value: "rx_weight",
    selected: false,
  },
  {
    displayName: "Actual Weight",
    value: "actual_weight",
    selected: false,
  },
  {
    displayName: "Rx RPE",
    value: "rx_rpe",
    selected: false,
  },
  {
    displayName: "Actual RPE",
    value: "actual_rpe",
    selected: false,
  },
  {
    displayName: "TUT",
    value: "tut",
    selected: false,
  },
  {
    displayName: "Time",
    value: "time",
    selected: false,
  },
  {
    displayName: "Tempo",
    value: "tempo",
    selected: false,
  },
  {
    displayName: "Rest",
    value: "rest",
    selected: false,
  },
];

export const STRUCTURE_CELL_OPTIONS = [
  {
    displayName: "Straight",
    value: "straight",
    isPopMenu: false,
  },
  {
    displayName: "Individual",
    value: "individual",
    isPopMenu: true,
    popUpMessage: "How many sets would you like for individual input?",
  },
  {
    displayName: "Superset",
    value: "superset",
    isPopMenu: false,
  },
  {
    displayName: "Pyramid",
    value: "pyramid",
    isPopMenu: true,
    popUpMessage: "How many sets would you like to Pyramid?",
  },
  {
    displayName: "Ascending (%)",
    value: "ascending",
    isPopMenu: true,
    popUpMessage: "How many sets would you like Ascending?",
  },
  {
    displayName: "Backdowns (%)",
    value: "backdowns",
    isPopMenu: true,
    popUpMessage: "How many sets would you like to Backdown on percentage?",
  },
  {
    displayName: "Ascending (RPE)",
    value: "ascending_rpe",
    isPopMenu: true,
    popUpMessage: "How many sets would you like Ascending (RPE)?",
  },
  {
    displayName: "Backdowns (RPE)",
    value: "backdowns_rpe",
    isPopMenu: true,
    popUpMessage: "How many sets would you like to Backdown (RPE)?",
  },
  {
    displayName: "Drop sets (qty)",
    value: "drop_sets",
    isPopMenu: true,
    popUpMessage: "How many sets would you like to Drop set?",
  },
  {
    displayName: "MYO (qty)",
    value: "myo",
    isPopMenu: true,
    popUpMessage: "How many sets would you like to MYO (qty)?",
  },
  {
    displayName: "Circuit",
    value: "circuit",
    isPopMenu: true,
    popUpMessage: "How many exercises would you like to include in the Circuit?",
  },
];

export const REPS = "reps";
export const SETS = "sets";
export const TIME = "time";
export const RX_LOAD = "rx_load";
export const RX_WEIGHT = "rx_weight";
export const RX_RPE = "rx_rpe";
export const ACTUAL_LOAD = "actual_load";
export const ACTUAL_WEIGHT = "actual_weight";
export const ACTUAL_RPE = "actual_rpe";
export const SET_STRUCTURE = "set_structure";
export const SUPERSET = "superset";
export const EXERCISE_ID = "exercise_id";
export const ROW_ID = "row_id";
export const LINKED_TO = "linked_to";
export const IS_PARENT = "isParent";

export const allowedDropColumns = ["reps", "sets", "rx_load", "rx_weight"];

export const DragAndDropPercentages = ["-5%", "-10%", "-15%", "+5%", "+10%", "+15%"];

export const RIR = "RIR";

export const AMRAP = "AMRAP";

export const EMOM = "EMOM";
