import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setIsStoppedNativusCoachSubscription } from "../../../redux/features/userSlice";
import AlertService from "../../common/Alert/alertService";
import CustomDialog from "../../common/CustomDialog";
import AccountDetails from "./AccountDetails";
import SelectPlan from "./SelectPlan";

const ChangePlanStoppedSubscription = ({ open = false, handleClose = () => {} }) => {
  const dispatch = useDispatch();
  const { warning, error, success } = AlertService();
  const [loader, setLoader] = useState(false);

  const {
    currentPlan: { planId },
  } = useSelector((state) => state.settings);

  const [selectedPlanId, setSelectedPlanId] = useState(planId);
  const [currentStepCount, setCurrentStepCount] = useState(0);

  const changePlanSteps = [
    {
      step: 0,
      label: "Reactivate your account - Please select a New Plan",
      component: <SelectPlan selectedPlanId={selectedPlanId} setSelectedPlanId={setSelectedPlanId} />,
      onNextClick: () => {
        if (!selectedPlanId) {
          warning("Please select any of plan");
          return;
        }
        if (selectedPlanId) setCurrentStepCount(currentStepCount + 1);
      },
      onPreviousClick: () => {
        console.log("Logging out......");
      },
      cancelTitle: "Logout",
      saveTitle: "Next",
    },
    {
      step: 1,
      label: "New Plan - Your New Payment Schedule",
      component: <AccountDetails selectedPlanId={selectedPlanId} />,
      onNextClick: () => {
        console.log("Comfirming the new plan");

        // dispatch Action to set isStoppedNativusCoachSubscription: false => success of API call
        dispatch(setIsStoppedNativusCoachSubscription(false));
        handleClose(); // close the pop-up
      },
      onPreviousClick: () => setCurrentStepCount(currentStepCount - 1),
      cancelTitle: "Cancel",
      saveTitle: "Confirm",
    },
  ];

  const currentStep = changePlanSteps[currentStepCount];

  return (
    <CustomDialog
      title={currentStep.label}
      open={open}
      handleClose={currentStep.onPreviousClick}
      dialogWidth={currentStepCount === 0 ? "lg" : "md"}
      handleSaveTitle={currentStep.saveTitle}
      handleCancelTitle={currentStep.cancelTitle}
      handleSave={currentStep.onNextClick}
      reverseButtons
      loader={loader}
      displayCloseIcon={false}
    >
      {currentStep.component}
    </CustomDialog>
  );
};

ChangePlanStoppedSubscription.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ChangePlanStoppedSubscription;
