import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Grid, InputLabel, TextField } from "@mui/material";

const PaymentForm = ({ control, errors, defaultValues, sx, register }) => {
  const formatExpiryDate = (value) => {
    const sanitizedValue = value.replace(/\D/g, "");
    if (sanitizedValue.length <= 2) {
      return sanitizedValue;
    }
    return `${sanitizedValue.slice(0, 2)}/${sanitizedValue.slice(2, 4)}`;
  };

  const formatCardNumber = (value) => {
    const onlyNums = value.replace(/\D/g, "");
    const limitedNums = onlyNums.slice(0, 16);
    return limitedNums.replace(/(.{4})/g, "$1 ").trim();
  };

  return (
    <Grid container sx={sx}>
      <Grid item xs={12}>
        <InputLabel shrink htmlFor="cardNumber">
          CARD NUMBER
        </InputLabel>
        <Controller
          name="cardNumber"
          control={control}
          defaultValue={defaultValues.cardNumber}
          render={({ field: { onChange, value } }) => (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="cardNumber"
              sx={{ marginTop: 0 }}
              value={formatCardNumber(value)}
              onChange={(e) => onChange(formatCardNumber(e.target.value))}
              error={!!errors.cardNumber}
              helperText={errors.cardNumber?.message}
            />
          )}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputLabel shrink htmlFor="cardExpiry">
            EXPIRY
          </InputLabel>
          <Controller
            name="cardExpiry"
            control={control}
            defaultValue={defaultValues.cardExpiry}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="cardExpiry"
                sx={{ marginTop: 0 }}
                value={formatExpiryDate(value)}
                onChange={(e) => onChange(formatExpiryDate(e.target.value))}
                error={!!errors.cardExpiry}
                helperText={errors.cardExpiry?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel shrink htmlFor="cvc">
            CVC
          </InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="cvc"
            sx={{ marginTop: 0 }}
            {...register("cardCVC")}
            error={!!errors.cardCVC}
            helperText={errors.cardCVC?.message}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InputLabel shrink htmlFor="cardName">
          NAME ON CARD
        </InputLabel>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="card-name"
          sx={{ marginTop: 0 }}
          {...register("cardName")}
          error={!!errors.cardName}
          helperText={errors.cardName?.message}
        />
      </Grid>
    </Grid>
  );
};

PaymentForm.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  defaultValues: PropTypes.shape({
    cardNumber: PropTypes.string,
    cardExpiry: PropTypes.string,
    cardCVC: PropTypes.string,
    cardName: PropTypes.string,
  }).isRequired,
  sx: PropTypes.object,
  register: PropTypes.func,
};

export default PaymentForm;
