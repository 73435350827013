import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import theme from "../../../theme";

const MediaUpload = ({ selectedFile, handleFileChange, handleRemoveFile, errors, acceptType = "image/*" }) => (
  <Stack>
    <Box
      sx={{
        width: "100%",
        height: "180px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        backgroundColor: theme.palette.white.light,
        cursor: "pointer",
        borderRadius: "12px",
      }}
      onClick={() => document.getElementById("fileInput").click()}
    >
      {selectedFile ? (
        <>
          <Box component="img" src={selectedFile} sx={{ width: "100%", height: "100%", objectFit: "contain" }} />
          <IconButton onClick={handleRemoveFile} sx={{ position: "absolute", top: 8, right: 8 }}>
            <CancelIcon />
          </IconButton>
        </>
      ) : (
        <Box sx={{ color: theme.palette.gray.dark }}>
          <WallpaperIcon />
        </Box>
      )}
    </Box>
    <input id="fileInput" type="file" accept={acceptType} style={{ display: "none" }} onChange={handleFileChange} />
    <Typography variant="body2" color="error" sx={{ fontSize: 12, margin: "3px 14px 0px" }}>
      {errors.image?.message}
    </Typography>
  </Stack>
);

MediaUpload.propTypes = {
  selectedFile: PropTypes.string,
  handleFileChange: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  acceptType: PropTypes.string,
};

export default MediaUpload;
