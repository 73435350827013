export const formTypes = [
  { value: "onboarding", name: "Onboarding" },
  { value: "feedback", name: "Feedback" },
  { value: "landing-page", name: "Landing Page" },
  { value: "check-in", name: "Check In" },
];

export const rangeOptions = [
  { value: "0-5", name: "0 - 5" },
  { value: "1-10", name: "1 - 10" },
];

export const FEEDBACK_CHECK_IN_FORM_FIELDS = [
  {
    fieldType: "text",
    defaultFieldLabel: "Free Text",
    fieldLabel: "",
    fieldTag: "FreeText",
    isRequired: false,
    readOnly: false,
    allowDuplicate: true,
    defaultValue: "",
  },
  {
    fieldType: "number",
    defaultFieldLabel: "Number",
    fieldLabel: "",
    fieldTag: "Number",
    isRequired: false,
    allowDuplicate: true,
  },
  {
    fieldType: "radio",
    defaultFieldLabel: "Radio Buttons (Single Choice)",
    fieldLabel: "",
    fieldTag: "Radio",
    isRequired: false,
    allowDuplicate: true,
    fieldOptions: [],
  },
  {
    fieldType: "checkbox",
    defaultFieldLabel: "Checkbox (Multiple Choice)",
    fieldLabel: "",
    fieldTag: "Checkbox",
    isRequired: false,
    allowDuplicate: true,
  },
  {
    fieldType: "range",
    defaultFieldLabel: "Range",
    fieldLabel: "",
    fieldTag: "Range",
    isRequired: false,
    fieldOptions: ["0-5"],
    allowDuplicate: true,
  },
];

export const ONBOARDING_LANDING_FORM_FIELDS = [
  {
    fieldType: "text",
    defaultFieldLabel: "First Name",
    fieldLabel: "",
    fieldTag: "FirstName",
    isRequired: false,
    allowDuplicate: false,
  },
  {
    fieldType: "text",
    defaultFieldLabel: "Last Name",
    fieldLabel: "",
    fieldTag: "LastName",
    isRequired: false,
    allowDuplicate: false,
  },
  {
    fieldType: "group",
    fieldGroup: "address",
    defaultFieldLabel: "Address",
    fieldLabel: "",
    fieldTag: "Address",
    isRequired: false,
    allowDuplicate: false,
    fields: [
      {
        fieldType: "text",
        fieldLabel: "Street Address",
        fieldTag: "StreetAddress",
        isRequired: false,
      },
      {
        fieldType: "text",
        fieldLabel: "Address line 2",
        fieldTag: "AddressLine2",
        isRequired: false,
      },
      {
        fieldType: "text",
        fieldLabel: "City",
        fieldTag: "City",
        isRequired: false,
      },
      {
        fieldType: "text",
        fieldLabel: "State",
        fieldTag: "State",
        isRequired: false,
      },
      {
        fieldType: "text",
        fieldLabel: "Post Code",
        fieldTag: "PostCode",
        isRequired: false,
      },
      {
        fieldType: "text",
        fieldLabel: "Country",
        fieldTag: "Country",
        isRequired: false,
      },
    ],
  },
  {
    fieldType: "text",
    defaultFieldLabel: "Phone Number",
    fieldLabel: "",
    fieldTag: "PhoneNumber",
    isRequired: false,
    allowDuplicate: false,
  },
  {
    fieldType: "text",
    defaultFieldLabel: "Email",
    fieldLabel: "",
    fieldTag: "Email",
    isRequired: false,
    allowDuplicate: false,
  },
  {
    fieldType: "date",
    defaultFieldLabel: "Date of Birth",
    fieldLabel: "",
    fieldTag: "DateofBirth",
    isRequired: false,
    allowDuplicate: false,
  },
  {
    fieldType: "dropdown",
    defaultFieldLabel: "Sex",
    fieldLabel: "",
    fieldTag: "Sex",
    isRequired: false,
    allowDuplicate: false,
    fieldOptions: ["Male", "Female", "Other"],
  },
  {
    fieldType: "file",
    defaultFieldLabel: "Signature",
    fieldLabel: "",
    fieldTag: "Signature",
    isRequired: false,
    allowDuplicate: false,
  },
  ...FEEDBACK_CHECK_IN_FORM_FIELDS,
];

export const allowedDuplicateFields = ["FreeText", "Number", "Radio", "Checkbox", "Range"];
