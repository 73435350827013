import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountOwnerDetails: {},
  currentPlan: {},
  cardDetails: {},
  shouldShowLeavePopUp: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAccountOwnerDetails: (state, action) => {
      state.accountOwnerDetails = action.payload;
    },
    setCurrentPlanDetails: (state, action) => {
      state.currentPlan = action.payload;
    },
    setCardDetails: (state, action) => {
      state.cardDetails = action.payload;
    },
    setCurrPlanId: (state, action) => {
      state.currentPlan.planId = action.payload.planId;
    },
    setShowShouldLeavePopUp: (state, action) => {
      state.shouldShowLeavePopUp = action.payload;
    },
  },
});

export const { setAccountOwnerDetails, setCurrentPlanDetails, setCardDetails, setCurrPlanId, setShowShouldLeavePopUp } =
  settingsSlice.actions;

export default settingsSlice.reducer;
