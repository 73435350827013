import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Avatar, Grid, Stack, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import useRoles from "../../../hooks/custom/useRoles";
import { setDrawer } from "../../../redux/features/basicSlice";
import { resetUserSettings } from "../../../redux/features/userSettingsSlice";
import { resetUserDetails } from "../../../redux/features/userSlice";
import { removeToken } from "../../../storage/user";
import { SignInRoute } from "../../route/routes";
import { sidebarAdminLinks, sidebarLinks } from "./sidebarLinks";
import { AuthorizationActions } from "../../api/RequestUtil";
import { generateToken } from "../../../notifications/firebase";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(72px + 1px)`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const { isManager } = useRoles();

  const [open, setOpen] = React.useState(true);
  const [openItems, setOpenItems] = React.useState({});

  const { firstName, lastName, email, profileImage } = user;

  const handleToggle = (itemText) => {
    setOpenItems((prev) => ({
      ...prev,
      [itemText]: !prev[itemText],
    }));
    setOpen(true);
  };

  const handleDrawer = () => {
    setOpen(!open);
    dispatch(setDrawer(!open));
    setOpenItems(() => ({
      ["Program Manager"]: !["Program Manager"],
      ["Admin"]: !["Admin"],
    }));
  };

  const renderSidebarItems = (items) => (
    <List sx={{ color: theme.palette.common.white }}>
      {items.map((item) => {
        const isSelected = location.pathname === item.path;
        return (
          <React.Fragment key={item.text}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => item.nested && handleToggle(item.text)}
                component={item.path ? Link : "button"}
                to={item.path}
                sx={{
                  backgroundColor: isSelected ? theme.palette.common.white : "transparent",
                  color: isSelected ? theme.palette.primary.main : theme.palette.common.white,
                  "&:hover": {
                    backgroundColor: isSelected ? theme.palette.common.white : theme.palette.action.hover,
                  },
                  padding: "12px",
                  marginX: "12px",
                  borderRadius: "10px",
                }}
              >
                <Tooltip title={item.text} placement="right">
                  <ListItemIcon>
                    <Box
                      component="img"
                      src={item.icon}
                      alt={item.text}
                      height="24px"
                      width="24px"
                      sx={{
                        filter: isSelected
                          ? "brightness(0) saturate(100%) invert(17%) sepia(9%) saturate(6598%) hue-rotate(121deg) brightness(97%) contrast(99%)"
                          : "brightness(1)",
                      }}
                    />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{ sx: { fontWeight: isSelected ? "700" : "400" } }}
                />
                {item.nested && open && (
                  <ListItemIcon>
                    {openItems[item.text] ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }} />
                    )}
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
            {item.nested && openItems[item.text] && open && renderNestedItems(item.nested)}
          </React.Fragment>
        );
      })}
    </List>
  );

  const renderSidebarContent = () => (
    <Box>
      {renderSidebarItems(sidebarLinks)}

      {isManager && (
        <>
          <Divider
            variant="middle"
            sx={{
              bgcolor: theme.palette.common.white,
              marginX: 1,
            }}
          />
          {renderSidebarItems(sidebarAdminLinks)}
        </>
      )}
    </Box>
  );

  const onLogout = async () => {
    const device_id = localStorage.getItem("device_id");
    const fcmToken = localStorage.getItem("fcm_token");
    if (fcmToken && device_id) {
      await destroyNotificationToken(device_id);
    }
    removeToken();
    dispatch(resetUserDetails());
    dispatch(resetUserSettings());
    navigate(SignInRoute);
    setTimeout(() => {
      generateToken();
    }, 2000);
  };

  const destroyNotificationToken = async (device_id) => {
    await AuthorizationActions.deleteTokenFromServer({ device_id: device_id });
  };

  const renderNestedItems = (nestedItems) => (
    <List>
      {nestedItems.map((item) => {
        const isSelected = location.pathname === item.path;
        return (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              sx={{
                backgroundColor: isSelected ? theme.palette.common.white : "transparent",
                color: isSelected ? theme.palette.primary.main : theme.palette.common.white,
                "&:hover": {
                  backgroundColor: isSelected ? theme.palette.common.white : theme.palette.action.hover,
                },
                padding: "12px",
                marginX: "12px",
                borderRadius: "10px",
              }}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{ sx: { marginLeft: "50px", fontWeight: isSelected ? "700" : "400" } }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            bgcolor: theme.palette.primary.main,
            color: theme.palette.common.white,
            transition: "width 0.3s",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.white.light,
              borderRadius: "0px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.primary.light,
              borderRadius: "0px",
            },
          },
        }}
      >
        <DrawerHeader>
          {open && (
            <Box
              component="img"
              src="/assets/images/Nativus_coach_logo-invert-stacked.gif"
              alt="Nativus logo"
              width="151px"
              height="68px"
            />
          )}
          <Box
            component="img"
            src="/assets/images/sidebar-left.svg"
            alt="sidebar left"
            height="27px"
            width="30px"
            sx={{ cursor: "pointer" }}
            onClick={handleDrawer}
          />
        </DrawerHeader>
        <Box display="flex" height="100vh" justifyContent="space-between" flexDirection="column">
          {renderSidebarContent()}
          {open && (
            <Box>
              <Divider
                variant="middle"
                sx={{
                  bgcolor: theme.palette.common.white,
                  marginX: 1,
                }}
              />
              <Grid
                display="flex"
                alignItems="center"
                gap={1}
                marginY={2}
                paddingLeft={1}
                paddingRight={3}
                justifyContent="space-between"
              >
                <Grid display="flex" alignItems="center" gap={1} margin={0.5} flexGrow={1}>
                  <Avatar src={profileImage} alt={`${firstName} ${lastName} image`} />
                  <Stack>
                    <Tooltip title={`${firstName ?? ""} ${lastName ?? ""}`} placement="top">
                      <Typography
                        fontSize="14px"
                        color={theme.palette.gray.light}
                        noWrap
                        sx={{
                          display: "block",
                          width: "141px",
                          overflow: "hidden !important",
                          textOverflow: "ellipsis !important",
                          whiteSpace: "nowrap !important",
                          maxWidth: "100%",
                          WebkitBoxOrient: "horizontal",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {`${firstName ?? ""} ${lastName ?? ""}`}
                      </Typography>
                    </Tooltip>
                    <Tooltip title={email || ""} placement="top">
                      <Typography
                        fontSize="14px"
                        color={theme.palette.gray.light}
                        noWrap
                        sx={{
                          display: "block",
                          width: "141px",
                          overflow: "hidden !important",
                          textOverflow: "ellipsis !important",
                          whiteSpace: "nowrap !important",
                          maxWidth: "100%",
                          WebkitBoxOrient: "horizontal",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {email || ""}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Tooltip title="Sign out" placement="right">
                  <Box
                    component="img"
                    src={"/assets/images/logout.svg"}
                    alt="logout logo"
                    sx={{ cursor: "pointer", width: 24, height: 24 }}
                    onClick={onLogout}
                  />
                </Tooltip>
              </Grid>
            </Box>
          )}
          {!open && (
            <Tooltip title="Sign out" placement="right">
              <Box
                component="img"
                src={"/assets/images/logout.svg"}
                alt="logout logo"
                margin={2}
                sx={{ cursor: "pointer", height: 30, width: 30 }}
              />
            </Tooltip>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
