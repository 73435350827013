import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import "./assets/styles/fc-styles.css";
import "./assets/styles/fui-styles.css";
import "./assets/styles/index.css";
import OnBoardingContextProvider from "./components/onBoarding/OnBoardingContextProvider";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      registration.active?.postMessage({
        type: "INIT_FIREBASE",
        firebaseConfig: {
          apiKey: process.env.REACT_APP_FB_API_KEY,
          authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
          projectId: process.env.REACT_APP_FB_PROJECT_ID,
          storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
          messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
          appId: process.env.REACT_APP_FB_APP_ID,
        },
      });
      // eslint-disable-next-line no-console
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error("Service Worker registration failed:", err);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <OnBoardingContextProvider>
        <App />
      </OnBoardingContextProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
