import { ROLES } from "../../constants/constant";

export const routeConfig = {
  authRoutes: [
    /^\/reset-password$/,
    /^\/sign-in$/,
    /^\/sign-up$/,
    /^\/forgot-password$/,
    /^\/set-password$/,
    /^\/verify-otp$/,
  ],
  publicRoutes: [/^\/[^/]+$/],
  privateRoutes: {
    [ROLES.MANAGER]: [
      /^\/admin\/forms$/,
      /^\/admin\/forms\/create-form$/,
      /^\/forms\/check-in-form-details\/[^/]+$/,
      /^\/admin\/forms\/[^/]+$/,
      /^\/admin\/landing-page$/,
      /^\/admin\/services$/,
      /^\/admin\/settings$/,
      /^\/admin\/users$/,
      /^\/assessments$/,
      /^\/blueprints$/,
      /^\/calendar$/,
      /^\/clients$/,
      /^\/clients\/[^/]+$/,
      /^\/([^/]+)\/view-program\/([^/]+)$/,
      /^\/clients\/create-new-blank-session\/([^/]+)\/([^/]+)$/,
      /^\/([^/]+)\/record-session-program-workout\/([^/]+)$/,
      /^\/clients\/new-lead\/[^/]+$/,
      /^\/dashboard$/,
      /^\/dashboard\/payments$/,
      /^\/dynamic-builder$/,
      /^\/dynamic-builder\/[^/]+$/,
      /^\/$/,
      /^\/market-hub$/,
      /^\/messages$/,
      /^\/tasks$/,
      /^\/[^/]+$/,
      /^\/onboarding$/,
      /^\/clients\/[^/]+\/sessions\/[^/]+\/details$/,
      /^\/clients\/[^/]+\/sessions\/[^/]+\/feedback$/,
    ],
    [ROLES.COACH]: [
      /^\/forms\/check-in-form-details\/[^/]+$/,
      /^\/assessments$/,
      /^\/blueprints$/,
      /^\/calendar$/,
      /^\/clients$/,
      /^\/clients\/[^/]+$/,
      /^\/([^/]+)\/view-program\/([^/]+)$/,
      /^\/clients\/create-new-blank-session\/([^/]+)\/([^/]+)$/,
      /^\/([^/]+)\/record-session-program-workout\/([^/]+)$/,
      /^\/clients\/new-lead\/[^/]+$/,
      /^\/dynamic-builder$/,
      /^\/dynamic-builder\/[^/]+$/,
      /^\/$/,
      /^\/dashboard$/,
      /^\/dashboard\/payments$/,
      /^\/market-hub$/,
      /^\/messages$/,
      /^\/tasks$/,
      /^\/onboarding$/,
      /^\/clients\/[^/]+\/sessions\/[^/]+\/details$/,
      /^\/clients\/[^/]+\/sessions\/[^/]+\/feedback$/,
    ],
  },
};
