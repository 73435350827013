import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomDialog from "../../common/CustomDialog";
import { TextField, Box } from "@mui/material";
import PropTypes from "prop-types";
import { resetPasswordSchema } from "../../../schemas/settingsSchema";
import { useState } from "react";
import { SettingsActions } from "../../api/RequestUtil";
import { SUCCESS } from "../../../utils/constants";
import AlertService from "../../common/Alert/alertService";
import { ErrorMessage } from "../../../utils/commonUtils";

const ResetPasswordModal = ({ open, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const { error, success } = AlertService();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(resetPasswordSchema),
    mode: "onTouched",
  });

  const handleCloseModal = () => {
    handleClose();
    reset();
  };

  const onSubmit = (data) => {
    const payload = {
      old_password: data.currentPassword,
      new_password: data.newPassword,
      confirm_password: data.confirmPassword,
    };
    setLoader(true);
    SettingsActions.resetAccountOwnerPassword(payload)
      .then(({ data }) => {
        if (data.status === SUCCESS) {
          success(data.message);
          handleCloseModal();
        }
      })
      .catch(({ data }) => error(ErrorMessage(data)))
      .finally(() => setLoader(false));
  };

  return (
    <CustomDialog
      title="Reset Password"
      open={open}
      handleClose={handleCloseModal}
      handleSave={handleSubmit(onSubmit)}
      loader={loader}
    >
      <Box component="form">
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            placeholder="Current password"
            type="password"
            variant="outlined"
            {...register("currentPassword")}
            error={!!errors.currentPassword}
            helperText={errors.currentPassword?.message}
            fullWidth
          />
          <TextField
            placeholder="New password"
            type="password"
            variant="outlined"
            {...register("newPassword")}
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            fullWidth
          />
          <TextField
            placeholder="Confirm new password"
            type="password"
            variant="outlined"
            {...register("confirmPassword")}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            fullWidth
          />
        </Box>
      </Box>
    </CustomDialog>
  );
};

ResetPasswordModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
export default ResetPasswordModal;
