import { JWT } from "../utils/constants";

export const isUserLoggedIn = () => {
  const status = localStorage.getItem(JWT);
  if (status) {
    return JSON.parse(status);
  }
  return null;
};

export const getJwtToken = () => localStorage.getItem(JWT);

export const storeJwtToken = async (token, rememberMe, loginDetails) => {
  localStorage.setItem(JWT, token);
  if (rememberMe) {
    localStorage.setItem("loginDetails", JSON.stringify(loginDetails));
  } else {
    localStorage.removeItem("loginDetails");
  }
};

export const removeToken = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("authToken");
};

export const getLocalStorageValue = (value) => localStorage.getItem(value);
export const setLocalStorageValue = (value) => localStorage.setItem(value);

export const getActiveOnboardingTab = (user) => {
  const onboardingSteps = ["your_details", "select_plan", "terms_and_conditions", "payment_setup"];

  return onboardingSteps.findIndex((onboardingStep) => user[onboardingStep] === false) !== -1
    ? onboardingSteps.findIndex((onboardingStep) => user[onboardingStep] === false)
    : onboardingSteps.length;
};
