import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, FormControl, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { sexOptions } from "../../../constants/settings.constants";
import theme from "../../../theme";
import MediaUpload from "../../common/FormField/MediaUpload";

const EditProfileFormFields = ({
  register,
  watch,
  setValue,
  trigger,
  errors,
  getValues,
  handleFileChange,
  handleRemoveFile,
  selectedFile,
}) => {
  const { dateFormat } = useSelector((state) => state.userSettings);

  return (
    <Box component="form">
      <Stack gap={1}>
        <TextField
          placeholder="First Name"
          fullWidth
          {...register("firstName")}
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
        />

        <TextField
          placeholder="Last Name"
          fullWidth
          {...register("lastName")}
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            fullWidth
            format={dateFormat.toUpperCase()}
            sx={{
              width: "100% !important",
              "& .MuiOutlinedInput-input": { width: "100% !important" },
              "& .MuiInputLabel-root": { fontSize: "14px", top: -5 },
            }}
            value={getValues("dateOfBirth") ? dayjs(getValues("dateOfBirth")) : null}
            {...register("dateOfBirth")}
            onChange={(newValue) => {
              setValue("dateOfBirth", newValue ? newValue.toDate() : null, { shouldDirty: true });
              trigger("dateOfBirth");
            }}
          />
        </LocalizationProvider>
        {errors?.dateOfBirth && (
          <Typography variant="body2" color="error">
            {errors.dateOfBirth?.message}
          </Typography>
        )}

        <FormControl fullWidth>
          <Select
            {...register("sex")}
            error={!!errors.sex}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
            defaultValue={watch("sex")}
            sx={{
              "& .MuiSelect-select": { fontSize: "16px" },
              "& .MuiSelect-placeholder": { fontSize: "16px" },
            }}
          >
            <MenuItem value="" disabled>
              <Typography
                color={theme.palette.gray.light}
                height="100%"
                display="flex"
                alignItems="center"
                fontSize={16}
              >
                Sex
              </Typography>
            </MenuItem>
            {sexOptions.map((type) => (
              <MenuItem value={type.value} key={type.value}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="body2" color="error" sx={{ fontSize: 12, margin: "3px 14px 0px" }}>
            {errors.sex?.message}
          </Typography>
        </FormControl>

        <TextField
          placeholder="Address"
          fullWidth
          {...register("address")}
          error={!!errors.address}
          helperText={errors.address?.message}
        />
        <TextField
          placeholder="Phone Number"
          fullWidth
          {...register("phone")}
          error={!!errors.phone}
          helperText={errors.phone?.message}
        />
        <TextField
          placeholder="Company Name"
          fullWidth
          {...register("companyName")}
          error={!!errors.companyName}
          helperText={errors.companyName?.message}
        />
        <TextField
          placeholder="Company Number"
          fullWidth
          {...register("companyNumber")}
          error={!!errors.companyNumber}
          helperText={errors.companyNumber?.message}
        />

        <MediaUpload
          selectedFile={selectedFile}
          handleFileChange={handleFileChange}
          handleRemoveFile={handleRemoveFile}
          errors={errors}
        />
      </Stack>
    </Box>
  );
};

EditProfileFormFields.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.string,
};

export default EditProfileFormFields;
