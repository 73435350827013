import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import basicSlice from "./features/basicSlice";
import blankSessionSlice from "./features/blankSessionSlice";
import calendarSlice from "./features/calendarSlice";
import dynamicBuilderSlice from "./features/dynamicBuilderSlice";
import formSlice from "./features/formSlice";
import settingsSlice from "./features/settingsSlice";
import userAvailabilitySlice from "./features/userAvailabilitySlice";
import userSettingsSlice from "./features/userSettingsSlice";
import userSlice from "./features/userSlice";

const rootReducer = combineReducers({
  basic: basicSlice,
  builder: dynamicBuilderSlice,
  blankSession: blankSessionSlice,
  user: userSlice,
  userSettings: userSettingsSlice,
  form: formSlice,
  settings: settingsSlice,
  calendar: calendarSlice,
  userAvailability: userAvailabilitySlice,
});

const persistConfig = {
  key: "nativus-store",
  storage,
  whitelist: ["user", "userSettings", "settings"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
