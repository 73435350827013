import { createTheme } from "@mui/material";

import componentStyleOverrides from "./componentStyleOverride";

const theme = createTheme({
  palette: {
    primary: {
      main: "#013D29",
    },
    black: {
      light: "#212121",
      dark: "#000008",
    },
    gray: {
      light: "#8C97A1",
      dark: "#8E9094",
      lighter: "#F9FAFB",
      darker: "#979797",
      medium: "#667085",
      semiTransparent: "#36454F40",
    },
    white: {
      light: "#EAEDF1",
      neutral: "#EAECF0",
    },
    red: {
      dark: "#8F1010",
    },
    sky: {
      light: "#009FD1",
    },
    pink: {
      light: "#FFEAEA",
      soft: "#FFBEBE",
    },
    lemon: {
      light: "#BBF247",
      dark: "#BBF24780",
      semiTransparent: "#BBF247CC",
      transparent: "#BBF24733",
    },
    neutral: {
      light: "#e0e0e0",
    },
    danger: "#D32F2F",
    charcoal: "#344054",
    icedBlue: "#C5E4D9",
    purple: "#5E38CA",
  },
  typography: {
    fontFamily: "PlusJakartaSans, Arial, sans-serif",
    body1: {
      fontSize: "14px",
    },
  },
  shape: {
    borderRadius: 12,
  },
});

theme.components = componentStyleOverrides(theme);

export default theme;
