import { Box, CircularProgress, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ErrorMessage } from "../../../utils/commonUtils";
import { SUCCESS } from "../../../utils/constants";
import { AuthorizationActions } from "../../api/RequestUtil";
import AlertService from "../../common/Alert/alertService";
import SelectPlanCard from "../../onBoarding/SelectPlanCard";

const SelectPlan = ({ selectedPlanId, setSelectedPlanId }) => {
  const { isRiskFreeTier } = useSelector((state) => state.user);

  const [loader, setLoader] = useState(false);
  const [allPlan, setAllPlan] = useState([]);
  const { error } = AlertService();

  const handleSelectPlan = (id) => {
    setSelectedPlanId(id);
  };

  const fetchAllPlan = () => {
    setLoader(true);
    AuthorizationActions.onBoardingGetAllPlan()
      .then(({ data }) => {
        if (data.status === SUCCESS) {
          setAllPlan(data.data);
        }
      })
      .catch(({ data }) => error(ErrorMessage(data)))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    fetchAllPlan();
  }, []);

  if (loader) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="65vh">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const updatedPlans = isRiskFreeTier ? allPlan : allPlan?.slice(1);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
      }}
    >
      {updatedPlans?.map((plan) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={plan.id} sx={{ display: "flex" }}>
          <SelectPlanCard
            data={plan}
            isSelected={plan.id === selectedPlanId}
            onClick={() => handleSelectPlan(plan.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

SelectPlan.propTypes = {
  selectedPlanId: PropTypes.string,
  setSelectedPlanId: PropTypes.func,
};

export default SelectPlan;
