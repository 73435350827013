import { Box, Stack, Typography } from "@mui/material";
import CustomDialog from "../../common/CustomDialog";
import PropTypes from "prop-types";
import theme from "../../../theme";

const PaymentSuccess = ({ open, handleClose }) => (
  <CustomDialog handleClose={handleClose} open={open} handleSaveTitle="Close" hideSaveButton handleCancelTitle="Close">
    <Stack>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" marginTop={2}>
        <Typography color={theme.palette.primary.main} fontWeight="600" fontSize="21px">
          Payment Details Updated Successfully!
        </Typography>
        <Box component="img" src="/assets/images/tick-circle-green.svg" height="100px" width="100px" marginTop={1} />
      </Box>
    </Stack>
  </CustomDialog>
);

PaymentSuccess.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default PaymentSuccess;
