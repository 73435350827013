import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountOwnerDetails: {},
  currentPlan: {},
  cardDetails: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setAccountOwnerDetails: (state, action) => {
      state.accountOwnerDetails = action.payload;
    },
    setCurrentPlanDetails: (state, action) => {
      state.currentPlan = action.payload;
    },
    setCardDetails: (state, action) => {
      state.cardDetails = action.payload;
    },
  },
});

export const { setAccountOwnerDetails, setCurrentPlanDetails, setCardDetails } = settingsSlice.actions;

export default settingsSlice.reducer;
