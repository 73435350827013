export const AdminFormsRoute = "/admin/forms";
export const AdminCreateFormRoute = "/admin/forms/create-form";
export const AdminEditFormRoute = "/admin/forms/:formId";
export const CheckInFormDetailsRoute = "/forms/check-in-form-details/:formResponseId";
export const AdminLandingPageRoute = "/admin/landing-page";
export const AdminServicesRoute = "/admin/services";
export const AdminSettingsRoute = "/admin/settings";
export const AdminUsersRoute = "/admin/users";
export const AdminApproveExerciseRoute = "/approve-exercise";
export const AssessmentRoute = "/assessments";
export const BlueprintsRoute = "/blueprints";
export const CalendarRoute = "/calendar";
export const ClientsRoute = "/clients";
export const ClientProfileRoute = "/clients/:clientId";
export const ClientViewProgram = "/:clientId/view-program/:programId";
export const ClientNewBlankSessionRoute = "/clients/create-new-blank-session/:clientId";
export const RecordSessionProgramWorkoutRoute = "/:clientId/record-session-program-workout/:sessionId";
export const DashBoardRoute = "/dashboard";
export const DynamicBuilderRoute = "/dynamic-builder";
export const DynamicBuilderEditRoute = "/dynamic-builder/:programId";
export const ForgotPasswordRoute = "/forgot-password";
export const HomeRoute = "/";
export const MarketHubRoute = "/market-hub";
export const MessageRoute = "/messages";
export const OnBoardingRoute = "/onboarding";
export const ResetPasswordRoute = "/reset-password";
export const SignInRoute = "/sign-in";
export const SignUpRoute = "/sign-up";
export const TasksRoute = "/tasks";
export const LandingPageRoute = "/:companyName";
export const SetPasswordRoute = "/set-password";
export const VerifyOTPRoute = "/verify-otp";
