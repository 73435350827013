import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PAYMENT_STATUS_PAST_DUE } from "../constants/constant";
import AlertService from "./common/Alert/alertService";
import Sidebar from "./common/Sidebar/Sidebar";
import ChangePlan from "./settings/account/ChangePlan";
import ChangePlanStoppedSubscription from "./settings/account/ChangePlanStoppedSubscription";
import UpdateBilling from "./settings/account/UpdateBilling";

function Layout({ children }) {
  const { warning } = AlertService();
  const {
    planStatus,
    planRemainingGraceDays,
    isRiskFreeTier,
    isCardDetailsFilled,
    isStoppedNativusCoachSubscription,
    isFromSettings,
  } = useSelector((state) => state.user);

  const [updateBillingMessage, setUpdateBillingMessage] = useState("");
  const [showUpdateBilling, setShowUpdateBilling] = useState(false);
  const [showChangePlan, setShowChangePlan] = useState(false);
  const [showChangePlanAfterUpdateBilling, setShowChangePlanAfterUpdateBilling] = useState(false);
  const [showChangePlanForStoppedSubscription, setShowChangePlanForStoppedSubscription] = useState(false);

  const handleCloseButtonClickUpdateBilling = () => {
    if (planRemainingGraceDays === 0) {
      warning(
        "You have exceeded the grace period. Please update your billing information to continue using the software."
      );
      return;
    }

    setShowUpdateBilling(false);
  };

  const handleCloseUpdateBilling = () => {
    if (planRemainingGraceDays === 0) return;

    setShowUpdateBilling(false);
  };

  const handleCloseButtonClickChangePlan = () => {
    if (planRemainingGraceDays === 0) {
      warning(
        "You have exceeded the grace period. Please update your billing information to continue using the software."
      );
      return;
    }

    setShowChangePlan(false);
  };

  const handleCloseChangePlan = () => {
    if (planRemainingGraceDays === 0) return;

    setShowChangePlan(false);
  };

  const handleCloseChangePlanStoppedSubscription = () => {
    setShowChangePlanForStoppedSubscription(false);
  };

  useEffect(() => {
    if (isStoppedNativusCoachSubscription) {
      setShowChangePlanForStoppedSubscription(true);
    } else if (isRiskFreeTier) {
      if (!isCardDetailsFilled && planRemainingGraceDays >= 0) {
        setShowUpdateBilling(true);
        setShowChangePlanAfterUpdateBilling(true);
      }

      if (isCardDetailsFilled) {
        !isFromSettings && setShowChangePlan(true);
      }
    } else {
      setShowUpdateBilling(planStatus === PAYMENT_STATUS_PAST_DUE ? true : false);
      setShowChangePlan(false);
    }

    setUpdateBillingMessage(
      planRemainingGraceDays === 0
        ? `Your Nativus Coach subscription is expired. Please update your billing information immediately to continue using the software. Access is disabled until payment is processed.`
        : `Your Nativus Coach subscription is currently expired. Please update your billing information immediately to continue using the software. Access will be disabled in ${planRemainingGraceDays} days if your payment is not processed.`
    );
  }, [planStatus, planRemainingGraceDays, isCardDetailsFilled, isRiskFreeTier, isStoppedNativusCoachSubscription]);

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, paddingY: 2, paddingX: "25px" }}>
        {children}
      </Box>

      <UpdateBilling
        updateBillingMessage={updateBillingMessage}
        open={showUpdateBilling}
        handleClose={handleCloseUpdateBilling}
        setChangePlan={setShowChangePlan}
        showChangePlanAfterUpdateBilling={showChangePlanAfterUpdateBilling}
        handleCloseButtonClick={handleCloseButtonClickUpdateBilling}
      />

      <ChangePlan
        open={showChangePlan}
        handleClose={handleCloseChangePlan}
        handleCloseButtonClick={handleCloseButtonClickChangePlan}
      />

      <ChangePlanStoppedSubscription
        open={showChangePlanForStoppedSubscription}
        handleClose={handleCloseChangePlanStoppedSubscription}
      />
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
