import { ThemeProvider } from "@mui/material";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./assets/styles/fonts.css";

import { AlertProvider } from "./components/common/Alert/AlertContext";
import Loader from "./components/route/Loader";
import PrivateRoute from "./components/route/PrivateRoute";
import {
  AdminCreateFormRoute,
  AdminEditFormRoute,
  AdminFormsRoute,
  AdminServicesRoute,
  AdminSettingsRoute,
  AdminUsersRoute,
  AssessmentRoute,
  BlueprintsRoute,
  ClientProfileRoute,
  ClientsRoute,
  DashBoardRoute,
  DynamicBuilderEditRoute,
  DynamicBuilderRoute,
  ForgotPasswordRoute,
  MarketHubRoute,
  OnBoardingRoute,
  ResetPasswordRoute,
  SignInRoute,
  SignUpRoute,
  TasksRoute,
} from "./components/route/routes";
import theme from "./theme";

const Assessments = lazy(() => import("./components/assessments"));
const BluePrints = lazy(() => import("./components/bluePrints"));
const Dashboard = lazy(() => import("./components/dashboard"));
const DynamicBuilder = lazy(() => import("./components/dynamicBuilder"));
const EditDynamicBuilder = lazy(() => import("./components/dynamicBuilder/EditDynamicBuilder"));
const ForgotPassword = lazy(() => import("./components/onBoarding/ForgotPassword"));
const NotFound = lazy(() => import("./components/route/NotFound"));
const OnBoarding = lazy(() => import("./components/onBoarding"));
const ResetPassword = lazy(() => import("./components/onBoarding/ResetPassword"));
const SignIn = lazy(() => import("./components/signIn"));
const SignUp = lazy(() => import("./components/onBoarding/SignUp"));
const Users = lazy(() => import("./components/users"));
const Clients = lazy(() => import("./components/clients"));
const ClientProfile = lazy(() => import("./components/clients/clientProfile"));
const Forms = lazy(() => import("./components/forms"));
const CreateForm = lazy(() => import("./components/forms/createForm"));
const EditForm = lazy(() => import("./components/forms/EditForm"));
const ServicesAndSubscriptions = lazy(() => import("./components/services-and-subscriptions"));
const Settings = lazy(() => import("./components/settings"));
const Tasks = lazy(() => import("./components/tasks"));
const MarketHub = lazy(() => import("./components/marketHub"));

const App = () => (
  <ThemeProvider theme={theme}>
    <AlertProvider>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Navigate to={SignInRoute} replace />} />
          <Route path={SignInRoute} element={<SignIn />} />
          <Route path={SignUpRoute} element={<SignUp />} />
          <Route path={OnBoardingRoute} element={<OnBoarding />} />
          <Route path={ForgotPasswordRoute} element={<ForgotPassword />} />
          <Route path={ResetPasswordRoute} element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
          <Route path={ClientsRoute} element={<PrivateRoute element={<Clients />} />} />
          <Route path={ClientProfileRoute} element={<PrivateRoute element={<ClientProfile />} />} />
          <Route path={DynamicBuilderRoute} element={<PrivateRoute element={<DynamicBuilder />} />} />
          <Route path={DynamicBuilderEditRoute} element={<PrivateRoute element={<EditDynamicBuilder />} />} />
          <Route path={DashBoardRoute} element={<PrivateRoute element={<Dashboard />} />} />
          <Route path={BlueprintsRoute} element={<PrivateRoute element={<BluePrints />} />} />
          <Route path={AdminUsersRoute} element={<PrivateRoute element={<Users />} />} />
          <Route path={AdminFormsRoute} element={<PrivateRoute element={<Forms />} />} />
          <Route path={AdminCreateFormRoute} element={<PrivateRoute element={<CreateForm />} />} />
          <Route path={AdminEditFormRoute} element={<PrivateRoute element={<EditForm />} />} />
          <Route path={AdminServicesRoute} element={<PrivateRoute element={<ServicesAndSubscriptions />} />} />
          <Route path={AssessmentRoute} element={<PrivateRoute element={<Assessments />} />} />
          <Route path={AdminSettingsRoute} element={<PrivateRoute element={<Settings />} />} />
          <Route path={TasksRoute} element={<PrivateRoute element={<Tasks />} />} />
          <Route path={MarketHubRoute} element={<PrivateRoute element={<MarketHub />} />} />
        </Routes>
      </Suspense>
    </AlertProvider>
  </ThemeProvider>
);

export default App;
