import dayjs from "dayjs";
import { z } from "zod";

export const addNewClientSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email"),
  clientType: z.string().min(1, "Please select the client type"),
  subscriptions: z.array(z.string()).min(1, "Please select at least one subscription"),
  feedbackForms: z.array(z.string()).optional(),
});

export const assignProgramToClientSchema = z.object({
  blueprint: z.string().min(1, "Blueprint is required"),
  startDate: z
    .date({
      required_error: "Start Date is required",
      invalid_type_error: "Start Date is required",
    })
    .refine((date) => date instanceof Date && !isNaN(date), {
      message: "Start Date is required",
    }),
});

export const addSubscriptionSchema = z.object({
  subscriptionId: z.string().min(1, "Subscription is required"),
  startDate: z
    .date({
      required_error: "Please fill the Date",
      invalid_type_error: "Please fill the Date",
    })
    .refine((date) => date instanceof Date && !isNaN(date), {
      message: "Start Date is required",
    }),
  paymentTermNumber: z.coerce.number().int().min(1, "Please enter Payment term number"),
  paymentTermFrequency: z.enum(["weeks", "fortnight", "months", "years", "ongoing"], {
    errorMap: () => ({ message: "Select a valid Payment term frequency" }),
  }),
  paymentAmount: z.number().positive("Payment amount must be greater than zero"),
  paymentFrequency: z.enum(["week", "fortnight", "month"], {
    errorMap: () => ({ message: "Select a valid Payment frequency" }),
  }),
  paymentCategory: z.string().min(1, "Payment category is required"),
  sessionsNumber: z.coerce.number().int().min(1, "Please enter Session number"),
  sessionsFrequency: z.enum(["week", "fortnight", "month"], {
    errorMap: () => ({ message: "Select a valid Session Frequency" }),
  }),
});

const scheduleCheckInBaseSchema = z.object({
  checkInForm: z.string().min(1, "Please select any Check-in form"),
  startDate: z
    .date({
      required_error: "Start Date is required",
      invalid_type_error: "Start Date is required",
    })
    .refine((date) => date instanceof Date && !isNaN(date), {
      message: "Start Date is required",
    }),
  endDate: z.date().optional().nullable(),
  time: z.date({ required_error: "Time is required" }).nullable(),
  frequency: z.enum(["daily", "weekly", "monthly"], {
    errorMap: () => ({ message: "Select a valid frequency" }),
  }),
  recurrenceDays: z.array(z.string()).optional(),
});

const scheduleCheckInValidation = (data, ctx) => {
  if (data.frequency === "week" && (!data.recurrenceDays || data.recurrenceDays.length === 0)) {
    ctx.addIssue({
      path: ["recurrenceDays"],
      message: "Recurrence days are required when frequency is 'week'",
    });
  }
};

export const scheduleCheckInFormSchema = scheduleCheckInBaseSchema.superRefine(scheduleCheckInValidation);
const recurringSchema = z.object({
  isRecurring: z.boolean(),
  recurrenceInterval: z.number().min(1, "Repeat interval must be at least 1").optional(),
  recurrenceFrequency: z.enum(["day", "week", "month", "year"]).optional(),
  recurrenceDays: z.array(z.string()).optional(),
  showEndDate: z.boolean().optional(),
  endDate: z.date().nullable().optional(),
});

// Common validation function for recurring logic
const recurringValidation = (data, ctx) => {
  if (data.isRecurring) {
    if (data.recurrenceInterval === undefined) {
      ctx.addIssue({
        path: ["recurrenceInterval"],
        message: "Recurrence interval is required when isRecurring is true",
      });
    }
    if (data.recurrenceFrequency === undefined) {
      ctx.addIssue({
        path: ["recurrenceFrequency"],
        message: "Recurrence frequency is required when isRecurring is true",
      });
    }
    if (data.showEndDate === undefined) {
      ctx.addIssue({
        path: ["showEndDate"],
        message: "Show end date is required when isRecurring is true",
      });
    }
    if (data.recurrenceFrequency === "week" && !data.recurrenceDays?.length) {
      ctx.addIssue({
        path: ["recurrenceDays"],
        message: "Recurrence days are required when recurrence frequency is 'week'",
      });
    }
    if (!data.showEndDate && (data.endDate === null || data.endDate === undefined)) {
      ctx.addIssue({
        path: ["endDate"],
        message: "End date is required when showEndDate is true",
      });
    }
  }
};

const baseSchema = z
  .object({
    startDate: z.date({ required_error: "Start date is required" }),
    startTime: z.date({ required_error: "Start time is required" }),
    endTime: z
      .instanceof(dayjs, {
        message: "End Time is required",
      })
      .nullable()
      .refine((time) => time && time.isValid(), {
        message: "End Time is invalid",
      }),
  })
  .merge(recurringSchema);

export const clientBookingSchema = baseSchema
  .extend({
    serviceOrSubscription: z.string().min(1, "Service/Subscription is required"),
  })
  .superRefine(recurringValidation);

export const rescheduleSchema = z.object({
  startDate: z
    .instanceof(dayjs, {
      message: "Start Date is required",
    })
    .nullable()
    .refine((date) => date && date.isValid(), {
      message: "Start Date is invalid",
    }),
  startTime: z
    .instanceof(dayjs, {
      message: "Start Time is required",
    })
    .nullable()
    .refine((time) => time && time.isValid(), {
      message: "Start Time is invalid",
    }),
  endTime: z
    .instanceof(dayjs, {
      message: "End Time is required",
    })
    .nullable()
    .refine((time) => time && time.isValid(), {
      message: "End Time is invalid",
    }),
});

export const cancelBookingSchema = z.object({
  isSessionDeducted: z.boolean().optional(),
});

export const assignAssessmentToClientSchema = z.object({
  selectedAssessment: z.string().min(1, "Please select an Assessment"),
  delivery: z.string().min(1, "Delivery option is required"),
  startDate: z
    .date({
      required_error: "Start Date is required",
      invalid_type_error: "Start Date is required",
    })
    .refine((date) => date instanceof Date && !isNaN(date), {
      message: "Start Date is required",
    }),
  endDate: z.date().nullable().optional(),
  // frequency: z.string().min("frequency is required"),
  frequency: z.enum(["monthly", "weekly", "fortnightly"], {
    errorMap: () => ({ message: "Select a valid frequency" }),
  }),
});

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif", "video/mp4", "video/gif"];

export const recordAssessmentSchema = z.object({
  measurementValue: z.string().min(1, "Please enter the Value"),
  measurementType: z.string().min(1, "Please select type of Measurement "),
  image: z
    .union([
      z.instanceof(File, "Image file is required").refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
        message: "File type must be an accepted image/video type",
      }),
      z.string().optional(),
    ])
    .nullable()
    .optional(),
});
