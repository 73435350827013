import axios from "axios";

import { ERR_NETWORK } from "../../constants/constant";
import { getJwtToken } from "../../storage/user";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getJwtToken();

    config.headers["Content-Type"] = config.headers["Content-Type"] || "application/json";

    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === ERR_NETWORK) {
      const newErr = {
        data: {
          message: "Oops! Something went wrong. Please check your internet connection and try again.",
        },
      };

      return Promise.reject(newErr);
    }

    return Promise.reject(error.response);
  }
);

export const axiosFileUploadInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
});

axiosFileUploadInstance.interceptors.request.use(
  (config) => {
    const token = getJwtToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosFileUploadInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === ERR_NETWORK) {
      const newErr = {
        data: {
          message: "Oops! Something went wrong. Please check your internet connection and try again.",
        },
      };

      return Promise.reject(newErr);
    }

    return Promise.reject(error.response);
  }
);

export default axiosInstance;
