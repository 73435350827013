import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ErrorComponent = ({ resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h1" component="h2" sx={{ fontSize: "5rem", fontWeight: "bold", color: "error.main" }}>
        Oops!
      </Typography>
      <Typography variant="h5" sx={{ marginY: 2, textAlign: "center" }}>
        Something went wrong.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 3, textAlign: "center" }}>
        We encountered an unexpected error. Please try again later.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => resetErrorBoundary()}>
        Reload Page
      </Button>
    </Box>
  );
};

ErrorComponent.propTypes = {
  resetErrorBoundary: PropTypes.func,
};

export default ErrorComponent;
