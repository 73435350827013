import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { getJwtToken } from "../../storage/user";
import { isFeatureExcluded, isRouteAllowed } from "../../utils/commonUtils";
import Layout from "../Layout";
import Unauthorize from "../Unauthorize";
import { DashBoardRoute, OnBoardingRoute, SignInRoute } from "./routes";
import ComingSoon from "../common/ComingSoon";

const PrivateRoute = () => {
  const location = useLocation();
  const token = getJwtToken();
  const { role, yourDetails, selectPlan, termsAndConditions, paymentSetup } = useSelector((state) => state.user);

  const isOnboarded = yourDetails && selectPlan && termsAndConditions && paymentSetup;
  const { isPublicRoute, isPrivateRoute } = isRouteAllowed(location.pathname, role);

  if (isPublicRoute) {
    if (!token) return <Outlet />;
    if (role && isOnboarded) return <Navigate to={DashBoardRoute} replace />;
  }

  if (isPrivateRoute) {
    if (!isOnboarded) {
      if (location.pathname !== OnBoardingRoute) {
        return <Navigate to={OnBoardingRoute} replace />;
      }
      return <Outlet />;
    }

    if (isFeatureExcluded(location.pathname)) {
      return (
        <Layout>
          <ComingSoon />
        </Layout>
      );
    }

    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return role ? <Unauthorize /> : <Navigate to={SignInRoute} />;
};

export default PrivateRoute;
