import {
  AdminFormsRoute,
  AdminLandingPageRoute,
  AdminServicesRoute,
  AdminSettingsRoute,
  AdminUsersRoute,
  AssessmentRoute,
  BlueprintsRoute,
  ClientsRoute,
  DashBoardRoute,
  DynamicBuilderRoute,
  MarketHubRoute,
  MessageRoute,
  CalendarRoute,
} from "../../route/routes";

const sidebarLinks = [
  { text: "Dashboard", icon: "/assets/images/dashboardIcon.svg", path: DashBoardRoute },
  { text: "Calendar", icon: "/assets/images/calendarIcon.svg", path: CalendarRoute },
  { text: "Clients", icon: "/assets/images/clientsIcon.svg", path: ClientsRoute },
  { text: "Messages", icon: "/assets/images/messagesIcon.svg", path: MessageRoute },
  {
    text: "Program Manager",
    icon: "/assets/images/programManagerIcon.svg",
    nested: [
      { text: "Dynamic Builder", path: DynamicBuilderRoute },
      { text: "Blueprints", path: BlueprintsRoute },
    ],
  },
  { text: "Tasks", icon: "/assets/images/tasksIcon.svg", path: "/tasks" },
  { text: "Assessments", icon: "/assets/images/assesmentsIcon.svg", path: AssessmentRoute },
  { text: "Market Hub", icon: "/assets/images/marketHubIcon.svg", path: MarketHubRoute },
];
const sidebarAdminLinks = [
  {
    text: "Admin ",
    icon: "/assets/images/adminSettingIcon.svg",
    nested: [
      { text: "Forms", path: AdminFormsRoute },
      { text: "Landing Page", path: AdminLandingPageRoute },
      { text: "Services", path: AdminServicesRoute },
      { text: "Users", path: AdminUsersRoute },
      { text: "Settings", path: AdminSettingsRoute },
    ],
  },
];

export { sidebarAdminLinks, sidebarLinks };
