import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const userAvailabilitySlice = createSlice({
  name: "userAvailability",
  initialState,
  reducers: {
    setUserAvailability: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setUserAvailability, setLoading, setError } = userAvailabilitySlice.actions;
export default userAvailabilitySlice.reducer;
