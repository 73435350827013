import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentView: "week",
  calendarStartDate: new Date(),
};

const calendarSlice = createSlice({
  name: "calendarView",
  initialState,
  reducers: {
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setCalendarStartDate(state, action) {
      state.calendarStartDate = action.payload;
    },
  },
});

export const { setCurrentView, setCalendarStartDate } = calendarSlice.actions;
export default calendarSlice.reducer;
