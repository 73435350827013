export const sexOptions = [
  { value: "male", name: "Male" },
  { value: "female", name: "Female" },
  { value: "other", name: "Other" },
];

export const defaultFormValues = {
  name: "",
  address: "",
  dateOfBirth: Date.now(),
  companyName: "",
  companyNumber: "",
  sex: "",
};

export const dateOptions = [
  { name: "DD/MM/YYYY", value: "dd/mm/yyyy" },
  {
    name: "MM/DD/YYYY",
    value: "mm/dd/yyyy",
  },
  {
    name: "YYYY-MM-DD",
    value: "yyyy-mm-dd",
  },
];

export const communicationNameMappings = {
  newBookings: "New Bookings",
  clientCheckIn: "Client completed a check in",
  cancelledBookings: "Cancelled Bookings",
  clientSentMessage: "Client sent a message",
  landingPageEnquiry: "Landing Page Enquiry",
  clientLoggedWorkout: "Client logged workout",
  rescheduledBookings: "Rescheduled Bookings",
  clientLoggedAssessment: "Client logged assessment",
  clientOnboardingComplete: "Client Onboarding Complete",
};

export const weightOptions = [
  {
    name: "Metric (kg)",
    value: "metric",
  },
  {
    name: "Imperial (lbs)",
    value: "imperial",
  },
];

export const distanceOptions = [
  {
    name: "Metric (kms)",
    value: "metric",
  },
  {
    name: "Imperial (miles)",
    value: "imperial",
  },
];

export const BOOKING_CONFIRMATION_TITLE = "Booking Confirmation Required";
export const CLIENT_INTERACTION_TASK = "Client Interaction Tasks";
export const ONBOARDING_TASKS = "Onboarding Tasks";
export const PROGRAM_DUE_TASKS = "Program Due Tasks";
export const TASKS_REMINDER = "Tasks Reminder";
