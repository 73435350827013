export const currencyOptions = [
  {
    name: "$ (Dollar)",
    value: "dollar",
  },
  {
    name: "£ (Pound)",
    value: "pound",
  },
  {
    name: "€ (Euro)",
    value: "euro",
  },
  {
    name: "kr (Krone)",
    value: "krone",
  },
  {
    name: "CHF (Swiss Franc)",
    value: "swiss-franc",
  },
  {
    name: "₽ (Russian Ruble)",
    value: "russian-ruble",
  },
  {
    name: "¥ (Yen)",
    value: "yen",
  },
  {
    name: "₹ (Rupee)",
    value: "rupee",
  },
  {
    name: "₩ (South Korean Won)",
    value: "south-korean-won",
  },
  {
    name: "R (South African Rand)",
    value: "south-african-rand",
  },
];

export const currencySymbols = [
  {
    name: "$",
    value: "dollar",
  },
  {
    name: "£",
    value: "pound",
  },
  {
    name: "€",
    value: "euro",
  },
  {
    name: "kr",
    value: "krone",
  },
  {
    name: "CHF",
    value: "swiss-franc",
  },
  {
    name: "₽",
    value: "russian-ruble",
  },
  {
    name: "¥",
    value: "yen",
  },
  {
    name: "₹",
    value: "rupee",
  },
  {
    name: "₩",
    value: "south-korean-won",
  },
  {
    name: "R",
    value: "south-african-rand",
  },
];
