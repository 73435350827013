import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import "./assets/styles/fc-styles.css";
import "./assets/styles/fui-styles.css";
import "./assets/styles/index.css";
import OnBoardingContextProvider from "./components/onBoarding/OnBoardingContextProvider";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

// ===================== ResizeObserver Error Handler =====================
const observerErrorHandler = (event) => {
  // Check if the error message includes "ResizeObserver loop"
  if (event.message && event.message.includes("ResizeObserver loop")) {
    // Ignore the ResizeObserver loop error
    return;
  }
  console.error(event);
};

window.addEventListener("error", observerErrorHandler);
window.addEventListener("unhandledrejection", (event) => {
  if (event.reason?.message?.includes("ResizeObserver loop")) {
    // Prevent unhandled promise rejections for ResizeObserver errors
    event.preventDefault();
  }
});
// =======================================================================

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(async (registration) => {
      console.log("Service Worker registered with scope:", registration.scope);

      await navigator.serviceWorker.ready;

      if (registration.active) {
        registration.active.postMessage({
          type: "INIT_FIREBASE",
          firebaseConfig: {
            apiKey: process.env.REACT_APP_FB_API_KEY,
            authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FB_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FB_APP_ID,
          },
        });
      }
    })
    .catch((err) => {
      console.error("Service Worker registration failed:", err);
    });
} else {
  console.warn("Service Worker is not supported in this browser.");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <OnBoardingContextProvider>
        <App />
      </OnBoardingContextProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
